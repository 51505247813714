<template>
  <div class="row justify-content-center justify-content-xxl-start align-items-center">
    <div class="col-xxl-6 d-flex flex-column justify-content-center w-fit">
      <h4 class="mb-2 text-nowrap">
        <span class="text-muted fw-normal">{{ $t('orders.list.total_clicks') }} :</span>
        {{ props.order.stats_clicks_total || 0 }}
      </h4>
      <h4 class="mb-0 text-nowrap">
        <span class="text-muted fw-normal">{{ $t('orders.list.impressions') }} :</span>
        {{ props.order.stats_impressions_total || 0 }}
      </h4>
    </div>
    <OrderBarGraph :data="clickStatsData" />
  </div>
</template>
<script setup lang="ts">
import { extractStats } from '@/func/statsCache';
import type { Order } from '@/types/types';
import { computed } from 'vue';
import OrderBarGraph from './OrderBarGraph.vue';

const props = defineProps<{ order: Order }>();
const clickStatsData = computed(() => extractStats(props.order.stats_cache, 'clicks'));
</script>
