import { defaultMinimumCampaignDuration, defaultMinimumDailyBudget } from '@/config/app';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import type { Order } from '@/types/types';
import { parseISO } from 'date-fns';

export interface OrderCreationData {
  minimum_budget_total: number; // minimum budget total
  minimum_budget_daily: number; // minimum budget daily
  minimum_duration_days: number; // minimum duration in days
  minimum_budget_total_mods: {
    mod: number;
    message: string;
  }[];
}

export function createEmptyOrderData(): OrderCreationData {
  const featureStore = useCustomerFeaturesStore();

  return {
    minimum_budget_total: featureStore.featureOrderManagment?.min_total_budget ?? 0,
    minimum_budget_daily: featureStore.featureOrderManagment?.min_daily_budget ?? defaultMinimumDailyBudget,
    minimum_duration_days: featureStore.featureOrderManagment?.min_duration_in_days ?? defaultMinimumCampaignDuration,
    minimum_budget_total_mods: [],
  };
}

/**
 * Calculate the number of days between start and end date of an order
 */
export function calculateOrderNumberOfDays(order: Order): number {
  return Math.round((parseISO(order.enddate).getTime() - parseISO(order.startdate).getTime()) / (1000 * 60 * 60 * 24));
}

/**
 * Check if an order has valid budget and duration settings
 */
export function hasOrderValidBudgetAndDurationSettings(order: Order, orderCreation: OrderCreationData): boolean {
  const ndays = calculateOrderNumberOfDays(order);
  const dailyBudget = (order.budget_gross_total ?? 0) / ndays;

  if (ndays < orderCreation.minimum_duration_days) {
    return false;
  }

  if ((order.budget_gross_total ?? 0) < orderCreation.minimum_budget_total) {
    return false;
  }

  if (dailyBudget < orderCreation.minimum_budget_daily) {
    return false;
  }

  return true;
}
