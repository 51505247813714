<template>
  <div>
    <HeaderNavbar>
      <HeaderNavbarSearch v-model="searchQuery"></HeaderNavbarSearch>
    </HeaderNavbar>
    <div class="container-lead">
      <li-page-header
        :title="$t('settings.companies.title')"
        :subtitle="$t('settings.companies.subtitle')"
        :has-filters="false"
      >
        <RouterLink :to="{ name: 'settings.companies.detail', params: { id: '+' } }" class="btn btn-primary"
          >{{ $t('company.create.button') }}<IconUserPlus :size="16" class="ms-2"></IconUserPlus
        ></RouterLink>
      </li-page-header>

      <RemoteListView endpoint="/v1/account/companies" :search-query="searchQuery" :resolve="resolve" class="mt-4">
        <template #loading>
          <div class="row">
            <div class="col-md-4" v-for="i in new Array(6)" :key="i">
              <div class="card mb-3">
                <div class="card-body p-4">
                  <h3 class="card-title mb-1">
                    <div class="placeholder col-7" />
                  </h3>
                  <div>
                    <div class="placeholder col-3" />
                    <br />
                    <div class="placeholder col-4" />
                    <br />
                    <div class="placeholder col-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #collection="{ collection: companies }">
          <div class="row">
            <div class="col-md-4" v-for="(company, index) in companies" :key="company.id">
              <div
                class="animate__animated animate__fadeInUp animate__faster card selectable-card mb-3"
                :style="{ animationDelay: `${index * 0.1}s` }"
              >
                <RouterLink
                  :to="{ name: 'settings.companies.detail', params: { id: company.id } }"
                  custom
                  v-slot="{ navigate }"
                >
                  <div class="card-body p-4" @click="navigate">
                    <div class="d-flex col-12">
                      <div v-if="company.logo_image.data" class="avatar-logo avatar me-3 rounded">
                        <img :src="company.logo_image.data.proxy.url_thumbnail" alt="" />
                      </div>
                      <div v-else>
                        <span class="avatar me-3 rounded">
                          {{ getAcronym(company.name) }}
                        </span>
                      </div>
                      <div>
                        <h2 class="fw-bold text-truncate mt-1">
                          {{ company.name }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </template>
        <template #footer="{ page, changePage, startRecordIndex, endRecordIndex, filterCount, pageCount }">
          <FixedFooterSection>
            <p class="m-0">
              <strong>{{ $t('orders.pagination.page') }} {{ page }} {{ $t('common.of') }} {{ pageCount }}</strong>
              <span class="text-muted">
                ({{ startRecordIndex }}-{{ endRecordIndex }} {{ $t('common.of') }} {{ filterCount }}
                {{ $t('settings.companies.title') }})
              </span>
            </p>
            <div class="d-flex gap-3">
              <button class="btn" :disabled="page <= 1" @click="changePage(page - 1)">
                {{ $t('orders.pagination.prev') }}
              </button>
              <button class="btn" :disabled="page >= pageCount" @click="changePage(page + 1)">
                {{ $t('orders.pagination.next') }}
              </button>
            </div>
          </FixedFooterSection>
        </template>
      </RemoteListView>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PageHeader as LiPageHeader } from '@prospective/lithium';

// import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';
import RemoteListView from '@/components/RemoteListView.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import { getAcronym } from '@/func/string/acronym';
import { IconUserPlus } from '@tabler/icons-vue';

/* --------------------------------- STATE -------------------------------- */

const searchQuery = ref('');
const resolve = 'logo_image';
</script>

<style lang="scss" scoped>
.text-truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1360px) {
  .text-truncate {
    width: 150px;
  }
}
@media screen and (max-width: 1150px) {
  .text-truncate {
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .text-truncate {
    width: 400px;
  }
}

@media screen and (max-width: 576px) {
  .text-truncate {
    width: 150px;
  }
}
.avatar-logo img {
  width: 30px;
}
.avatar-logo {
  min-width: 40px;
  border: 2px solid rgba(191, 194, 193, 0.205);
  overflow: hidden;
}
</style>
