<template>
  <span class="avatar" style="background-image: url()" v-if="userStore.currentUser?.email">{{
    getAcronym(`${userStore.currentUser?.firstname} ${userStore.currentUser?.lastname}`)
  }}</span>
</template>

<script setup lang="ts">
import { getAcronym } from '@/func/string/acronym';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
</script>
