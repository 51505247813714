<template>
  <div>
    <div class="d-flex align-items-start justify-content-between">
      <OrderDetails :order="props.order" class="me-1" />
      <OrderBanner class="col-auto" :order="props.order" />
    </div>
    <div class="d-flex gap-4 align-items-center justify-content-start mt-3">
      <OrderSocialStats class="col-auto" :order="props.order" />
      <div class="vr" />
      <OrderExpirySlider class="col-4" :order="props.order" />
    </div>

    <OrderPills class="mt-3" :order="props.order" />
  </div>
</template>
<script setup lang="ts">
import OrderBanner from './order_elements/OrderBanner.vue';
import OrderExpirySlider from './order_elements/OrderExpirySlider.vue';
import OrderSocialStats from './order_elements/OrderSocialStats.vue';
import OrderDetails from './order_elements/OrderDetails.vue';
import type { Order } from '@/types/types';
import OrderPills from './order_elements/OrderPills.vue';

const props = defineProps<{ order: Order }>();
</script>
