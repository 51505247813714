import type { Company, ImageObject } from '@/types/types';
import { request } from './client';

export function fetchCompanies(parameters: object): Promise<{ data: Company[] }> {
  return request('GET', '/v1/account/companies', parameters);
}
export function fetchCompany(id: number, parameters?: object): Promise<{ data: Company }> {
  return request('GET', `/v1/account/companies/${id}`, parameters);
}
export function fetchCompanyImages(id: number, parameters?: object): Promise<{ data: ImageObject[] }> {
  return request('GET', `/v1/account/companies/${id}/images`, parameters);
}

export function fetchImageMeta(imageHash): Promise<{ data: ImageObject }> {
  return request('GET', `/v1/media/images/${imageHash}`, { meta: 1 });
}

export function createCompanyImage(companyId: string | number, addCompanyImage: ImageObject) {
  return request('POST', `/v1/account/companies/${companyId}/images`, {}, addCompanyImage);
}
export function deleteCompanyImage(companyId: string | number, deleteCompanyImageId: string | number) {
  return request('DELETE', `/v1/account/companies/${companyId}/images/${deleteCompanyImageId}`);
}
