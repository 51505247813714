<template>
  <HeaderNavbar></HeaderNavbar>
  <div class="container-lead">
    <div class="responsive-padding row gap-4 justify-content-center" v-if="!reqIqMode">
      <template v-if="featureStore.featureOrderManagment">
        <div class="info-card col-12 col-md-6 col-lg-5 col-xl-4">
          <div class="card m-0">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img width="161" height="161" src="/svgs/allOrders.svg" />
                <h3 class="pt-1 fw-bold">{{ $t('home.see_all_orders_heading') }}</h3>
                <p class="text-muted m-0">{{ $t('home.see_all_orders_subheading') }}</p>
                <RouterLink
                  :to="{ path: goToOrdersLink }"
                  class="btn btn-dark btn-pill text-light mt-4 text-capitalize"
                  >{{ $t('home.see_all_orders') }}</RouterLink
                >
              </div>
            </div>
          </div>
        </div>
        <div class="info-card col-12 col-md-6 col-lg-5 col-xl-4" v-if="featureStore.featureCanCreateEditOrder">
          <div class="card border-primary m-0">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img width="161" height="161" src="/svgs/newOrder.svg" />
                <h3 class="pt-1 fw-bold">{{ $t('home.create_order_heading') }}</h3>
                <p class="text-muted m-0">{{ $t('home.create_order_subheading') }}</p>
                <RouterLink
                  :to="{ path: createOrderLink }"
                  class="btn btn-pill btn-primary text-light mt-4 text-capitalize"
                  >{{ $t('home.create_order') }}</RouterLink
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="featureStore.featurePoolMirrorCampaign">
        <div class="info-card col-12 col-md-6 col-lg-5 col-xl-4">
          <div class="card m-0">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img width="161" height="161" src="/svgs/poolmirror.svg" />
                <h3 class="pt-1 fw-bold">{{ $t('home.see_all_poolmirror_heading') }}</h3>
                <p class="text-muted m-0">{{ $t('home.see_all_poolmirror_subheading') }}</p>
                <RouterLink
                  :to="{ path: goToPoolmirrorLink }"
                  class="btn btn-dark btn-pill text-light mt-4 text-capitalize"
                  >{{ $t('home.see_all_poolmirror') }}</RouterLink
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <PageHeader
        :title="$t('promptsuite.welcome.title')"
        :subtitle="$t('promptsuite.welcome.subtitle')"
        :has-filters="false"
        class="mb-4"
      >
        <RouterLink class="btn btn-primary text-white" :to="{ name: 'promptsuite.apps' }">
          {{ $t('promptsuite.welcome.button_to_apps') }} <IconArrowRight class="ms-2" :size="20" />
        </RouterLink>
      </PageHeader>
      <div class="d-flex justify-content-left">
        <div class="card home-text-card col-lg-9 mb-5">
          <div class="p-5">
            <div class="text-center">
              <img src="./../assets/logo/logo_reciq.svg" class="logo-header" />
            </div>
            <div v-html="homeMarkdown"></div>
            <RouterLink class="btn btn-primary mt-3 text-white" :to="{ name: 'promptsuite.apps' }">
              {{ $t('promptsuite.welcome.button_to_apps') }} <IconArrowRight class="ms-2" :size="20" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { appType } from '@/config/app';
import { PageHeader } from '@prospective/lithium';
import { IconArrowRight } from '@tabler/icons-vue';
import { marked } from 'marked';
import HeaderNavbar from './header/HeaderNavbar.vue';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import { computed } from 'vue';

const featureStore = useCustomerFeaturesStore();

const goToOrdersLink = '/dashboard/orders';
const createOrderLink = '/dashboard/orders/create';

const reqIqMode = appType === 'HRIQ';

const goToPoolmirrorLink = computed(() => {
  const campaign_id = featureStore?.featurePoolMirrorCampaign?.active_campaign_id;
  if (campaign_id) return `/dashboard/poolmirror/${campaign_id}/detail`;
  return `dashboard/home`;
});

const homeMarkdown = marked(`
# Hallo,

Wir freuen uns, dass Du unsere **innovative Recruiting-Software** nutzt und dabei hilfst, die Zukunft des Personalmanagements zu gestalten. Unsere Software basiert auf **künstlicher Intelligenz (AI)**, was es uns ermöglicht, den Recruiting-Prozess zu optimieren und Dir wertvolle Unterstützung bei der Suche nach den besten Talenten zu bieten. Wir möchten Dich jedoch auf einige wichtige Aspekte hinweisen, die Du bei der Verwendung unserer Software berücksichtigen solltest.

## 1. AI-basierte Funktionen und deren Zuverlässigkeit:

Unsere Software verwendet **AI-Technologien**, um Dir bei deiner Arbeit zu helfen und dich kreativ zu unterstützen. Bitte beachte jedoch, dass die Ergebnisse nicht immer zu **100% zuverlässig** sein können. Künstliche Intelligenz lernt und entwickelt sich ständig weiter, aber es besteht immer das Risiko von Fehlern oder ungenauen Einschätzungen. Wir empfehlen daher *dringend*, die von unserer Software bereitgestellten Informationen sorgfältig zu überprüfen und bei Unklarheiten manuelle Überprüfungen durchzuführen.

## 2. Beta-Status und Datensicherheit:

Unsere Recruiting-Software befindet sich derzeit im **Beta-Status**. Dies bedeutet, dass sie zwar leistungsstark und vielversprechend ist, jedoch noch in der Entwicklungsphase und nicht vollständig ausgereift ist. Wir arbeiten kontinuierlich daran, sie zu verbessern und zuverlässiger zu gestalten.

Darüber hinaus möchten wir Dich darauf hinweisen, dass die Daten, die Du in unserer Software eingibst, in die **USA übermittelt** werden können. Unsere Software basiert auf **OpenAI-Technologien**, was die Übermittlung von Daten in die USA notwendig macht. Bitte behandele daher sensible Informationen mit der gebotenen Vorsicht und stelle sicher, dass Du die **Datenschutzrichtlinien und -bestimmungen** unseres Unternehmens verstehst.

Wir sind uns der Bedeutung des Schutzes Deiner Daten bewusst und setzen alles daran, höchste Standards für **Datensicherheit und Datenschutz** einzuhalten.

Wir danken Dir für Dein Verständnis und Deine Geduld, während wir unsere Recruiting-Software weiterentwickeln und verbessern. Deine Rückmeldungen sind für uns äußerst wertvoll, um unser Produkt stetig zu optimieren.

Bei Fragen oder Anliegen steht Dir unser **Support-Team** gerne zur Verfügung. Wir sind stolz darauf, Dich auf Deiner Reise im Bereich des modernen Recruitings begleiten zu dürfen und freuen uns auf die gemeinsame Zusammenarbeit.


`);
</script>

<style scoped lang="scss">
.info-card {
  display: flex;
  justify-content: center;
  max-width: 370px;
}
.home-text-card {
  > div {
    font-size: 1.2rem;
  }

  .logo-header {
    max-width: 8%;
    margin-top: -50px;
    margin-bottom: 1rem;
    opacity: 0.7;
  }
}

.responsive-padding {
  padding-top: 15vh;
  padding-bottom: 15vh;
}
.card-body {
  padding: 50px;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .responsive-padding {
    padding-top: 5rem;
  }
}
</style>
