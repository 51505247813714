<template>
  <div>
    <div class="card card-sm order-details">
      <div class="card-body">
        <h3 class="mb-3">{{ props.title }}</h3>
        <div class="d-flex justify-content-between align-items-center">
          <p class="info">
            {{ props.info }} <small class="info-description" style="">{{ props.info_description }}</small>
          </p>
          <OrderBarGraph v-if="show_graph" :data="props.graph_data" />
        </div>

        <div class="hr my-2" v-if="props.footer" />
        <p v-if="props.footer" class="text-muted">{{ props.footer }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import OrderBarGraph, { type StatsGraphDataItem } from './order_elements/OrderBarGraph.vue';

const props = withDefaults(
  defineProps<{
    title: string;
    footer?: string;
    info: string | number;
    info_description?: string;
    graph_data?: StatsGraphDataItem[];
    show_graph?: boolean;
  }>(),
  { graph_data: () => [], show_graph: true }
);
</script>
<style scoped>
p {
  margin: 0;
}
.order-details .info {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  font-size: 30px;
  font-weight: 500;
}
.info-muted .order-details .info {
  font-size: 18px;
  color: var(--tblr-muted);
}
.order-details .info-description {
  font-size: 12px;
  margin-left: 0.5em;
}
</style>
