<template>
  <div
    class="card"
    @drop.prevent="onDrop"
    @dragover.prevent
    @dragenter.prevent.stop="calcDrag('dragenter')"
    @dragleave.prevent.stop="calcDrag('dragleave')"
  >
    <div class="chat" ref="chatBubblesContainer">
      <div>
        <div v-if="isLoading" class="p-4 text-center">
          <div class="spinner-border"></div>
        </div>
        <div v-else-if="messages.length === 0" class="pt-5 text-center">
          <span class="text-muted">{{ $t('order.chat.no_messages') }}</span>
        </div>
        <TransitionGroup name="fade-in-left" tag="div" class="chat-bubbles">
          <ChatMessage v-for="message in messages || []" :key="message.id" :message="message"></ChatMessage>
        </TransitionGroup>

        <Transition name="fade-in-left">
          <div class="chat-bubbles" v-if="currentlyTypingString">
            <div class="chat-item">
              <div class="row align-items-end">
                <div class="col-auto">
                  <span class="avatar"></span>
                </div>
                <div class="col-auto">
                  <div class="chat-bubble">
                    <div class="chat-bubble-body">
                      <p class="text-secondary text-italic">
                        {{ currentlyTypingString }}<span class="animated-dots"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div class="chat-bottom-anchor" style="height: 2px"></div>
      </div>
    </div>
    <div class="card-footer">
      <MentionableTextarea
        class="textarea-field ps-2 pt-1"
        :class="{ disabled: isSending }"
        v-model="currentMessage"
        :disabled="isSending"
        @input="handleChatTyping"
        @submit="sendMessage"
        :mentions="availableMetions"
      >
        <div class="chat-actions">
          <div class="chat-actions-text"></div>
          <div class="chat-actions-interacions">
            <label class="dropzone" for="filesUpload">
              <i class="ti ti-m ti-paperclip ms-1" style="font-size: 1.2rem" role="button" />
              <input
                class="d-none"
                type="file"
                id="filesUpload"
                :multiple="true"
                @change="onInputChange"
                tabindex="-1"
              />
            </label>

            <i class="ti ti-m ti-send mx-2" style="font-size: 1.2rem" role="button" @click="sendMessage" />
          </div>
        </div>
      </MentionableTextarea>
      <div class="chat-attachments">
        <span class="tag attachment border" v-for="(att, index) in fileAttachments" :key="att.id">
          {{ att.filename }}
          <button href="#" class="btn-close" @click="removeAttachment(index)" />
        </span>
      </div>
    </div>
    <div class="chat-card-drop text-white" v-if="isDragging">
      <i class="ti ti-cloud-upload" style="font-size: 4rem"></i>
      <h2>Drop files here</h2>
    </div>
  </div>
</template>
<script setup lang="ts">
import { fetchChatMessages, sendChatMessage, fetchChatMentions } from '@/api/chat';
import type { IChatChannel, IChatMessage, IAttachment } from '@/types/chat.js';
import { computed, nextTick, onMounted, onUnmounted, ref, triggerRef } from 'vue';
import { useToast } from 'vue-toastification';
import { debounce } from '@prospective/lithium';
import ChatMessage from './components/ChatMessage.vue';
import MentionableTextarea from './components/ChatTextarea.vue';
import { uploadAttachment } from '@/api/chat.js';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const toast = useToast();
const userStore = useUserStore();
const { ws } = storeToRefs(userStore);

/* ---------------------------------- PROPS --------------------------------- */
const props = withDefaults(
  defineProps<{
    channel: string;
  }>(),
  {}
);

/* --------------------------------- STATE ---------------------------------- */
const chatBubblesContainer = ref<HTMLElement | null>(null);
const messages = ref<IChatMessage[]>([]);
const chatChannel = ref<IChatChannel | null>(null);
const isLoading = ref(false);
const allDataLoaded = ref(false);
const currentMessage = ref('');
const isSending = ref(false);
const currentlyTyping = ref<Set<string>>(new Set());
const availableMetions = ref<{ [mention: string]: unknown }>({});

let lastMessageId = 0;

/* --------------------------------- COMPUTED ---------------------------------- */

const currentlyTypingString = computed(() => {
  if (currentlyTyping.value.size === 0) {
    return '';
  }

  if (currentlyTyping.value.size === 1) {
    return `${currentlyTyping.value.values().next().value} is typing`;
  }

  return `${Array.from(currentlyTyping.value).join(', ')} are typing`;
});

/* -------------------------------- METHODS -------------------------------- */

function scrollToLastMessage() {
  // somehow next tick aint enough here, im guessing the transition causes
  // the element only one tick later to be rendered. but I don't know adding
  // а short delay gives us the desired effect.
  setTimeout(() => {
    const lastMessageElement = chatBubblesContainer.value;
    if (!lastMessageElement) return;
    // Scroll the card container to the bottom
    lastMessageElement.scrollTop = lastMessageElement.scrollHeight - lastMessageElement.clientHeight;
  }, 16);
}

function fetchMessages(silent = false) {
  if (!silent) isLoading.value = true;

  fetchChatMessages(props.channel)
    .then((response) => {
      messages.value = response?.data ?? [];
      chatChannel.value = response?.chat_channel ?? null;
      currentlyTyping.value = new Set([]);

      nextTick(() => {
        scrollToLastMessage();
      });
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function fetchMoreMessages() {
  if (!chatChannel.value || allDataLoaded.value) {
    return;
  }

  isLoading.value = true;

  // store the current bottom offset of the chat bubbles container
  const currentBottomOffset = chatBubblesContainer.value?.scrollHeight ?? 0;

  fetchChatMessages(props.channel, messages.value[0].id)
    .then((response) => {
      if (response?.data?.length === 0) {
        allDataLoaded.value = true;
        return;
      }

      messages.value = [...(response?.data ?? []), ...messages.value];
    })
    .finally(() => {
      isLoading.value = false;

      // calculate the new bottom offset of the chat bubbles container
      const newBottomOffset = chatBubblesContainer.value?.scrollHeight ?? 0;
      let difference = newBottomOffset - currentBottomOffset;

      difference -= 150; // loading spinner

      chatBubblesContainer.value?.scrollTo(0, difference);
    });
}

function sendMessage() {
  if (!currentMessage.value && !fileAttachments.value.length) return;

  isSending.value = true;

  sendChatMessage(props.channel, currentMessage.value, fileAttachments.value)
    .then((response) => {
      lastMessageId = response!.data.id;
      messages.value = [...messages.value, response!.data];
      fileAttachments.value = [];
    })
    .finally(() => {
      isSending.value = false;
      currentMessage.value = '';
      scrollToLastMessage();
    });
}

function handleScroll() {
  if (isLoading.value) return;
  if (!chatBubblesContainer.value) return;
  // If the scrollTop value is close to 0, it means user has scrolled to the top.
  if (chatBubblesContainer.value.scrollTop < 10) {
    fetchMoreMessages();
  }
}

function handleChatTyping() {
  ws.value?.sendAction('chat.typing', { channel: props.channel });
}

function keepChatChannelJoined() {
  ws.value?.connect().then(() => {
    ws.value?.sendAction('chat.join', { channel: props.channel });
  });
}

function handleNewMessageNotification(message: { message_id: number }) {
  if (message.message_id != lastMessageId) {
    fetchMessages(true);
  }
}

function removeChatTypingHints(name: string) {
  currentlyTyping.value.delete(name);
  triggerRef(currentlyTyping);
}

const removeChatTypingHintsDebounced = debounce(removeChatTypingHints, 2000);

function handleChatTypingNotification(data: { name: string }) {
  // only when the typing messages was hidden till now scroll down.
  if (currentlyTyping.value.size === 0) {
    nextTick(() => {
      scrollToLastMessage();
    });
  }

  currentlyTyping.value.add(data.name);
  triggerRef(currentlyTyping);
  removeChatTypingHintsDebounced(data.name);
}

/* ------------------ FILE ATTACHMENTS BROWSE OR DRAG-DROP ------------------ */
const dragCounter = ref(0);
const fileAttachments = ref<IAttachment[]>([]);
const isDragging = computed(() => dragCounter.value > 0);
const calcDrag = (event: string) => {
  if (event === 'dragenter') dragCounter.value++;
  else if (event === 'dragleave') dragCounter.value--;
  else if (event === 'drop') dragCounter.value = 0;
};
function onInputChange(e: any) {
  if (!e?.target) return;
  addFiles(e.target.files);
  e.target.value = null;
}
function onDrop(e) {
  calcDrag('drop');
  addFiles(e.dataTransfer?.files);
}

function addFiles(attachments: FileList | []) {
  const maxBytes = 10_000_000; // 10MB
  if (!attachments.length) return;

  for (const f of [...attachments] as File[]) {
    /* ------------------- If the file is executable, skip it ------------------- */
    if (f.type.includes('application/x-')) continue;
    if (f.size > maxBytes) {
      toast.error(`File ${f.name} bigger than 10MB`);
      continue;
    }
    startUpload(f);
  }
}
async function startUpload(file: File) {
  if (!file) return;

  const formData = new FormData();
  formData.append('file', file);

  try {
    const res: { data: IAttachment } = await uploadAttachment(formData);
    if (res.data) fileAttachments.value.push(res.data);
  } catch (error) {
    console.error(error);
    toast.error(`Failed to upload '${file.name}'. Try again`);
  }
}
function removeAttachment(index) {
  if (fileAttachments.value.length - 1 >= index) fileAttachments.value.splice(index, 1);
}

/* ------------------------------- LIFECYCLE ------------------------------ */

let fetchMessagesInterval;

onMounted(() => {
  fetchMessages();
  fetchChatMentions(props.channel)
    .then((res) => (availableMetions.value = res.data))
    .catch((e) => console.error('Failed to retrieve mentions', e));
  chatBubblesContainer.value?.addEventListener('scroll', handleScroll);

  // we need to continuously send the chat.join action to the server
  // because the server will evict us from the channel after a certain amount of time
  // this is because the server cannot really trust us to send the chat.leave action
  fetchMessagesInterval = setInterval(keepChatChannelJoined, 5000);
  keepChatChannelJoined();

  // register socket event handlers
  ws.value?.registerActionHandler('chat.message.new', handleNewMessageNotification);
  ws.value?.registerActionHandler('chat.typing', handleChatTypingNotification);
});

onUnmounted(() => {
  chatBubblesContainer.value?.removeEventListener('scroll', handleScroll);
  clearInterval(fetchMessagesInterval);

  ws.value?.connect().then(() => {
    ws.value?.sendAction('chat.leave', { channel: props.channel });
  });

  // unregister socket event handlers
  ws.value?.unregisterActionHandler('chat.message.new', handleNewMessageNotification);
  ws.value?.unregisterActionHandler('chat.typing', handleChatTypingNotification);
});
</script>

<style scoped>
.chat .chat-bubbles {
  padding: 2rem;
}
.card {
  position: relative;
}
.card-footer {
  background-color: initial !important;
  color: #91979e;
}
/* mimic textarea bottom border */
.card-footer .textarea-field {
  border: 1px solid #e5e6e8;
  border-top: 1;
}
.card-footer .chat-actions {
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
}
.card-footer .chat-attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.card-footer .chat-attachments > .attachment {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1em;
  padding: 2px 6px;
  background-color: var(--tblr-primary-lt);
}
.card-footer .chat-attachments > .attachment .btn-close {
  margin: 0 0.1rem 0 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.4rem;
}
/* but remove textarea bottom border */
.card-footer :deep textarea {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.chat-card-drop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* fade-in-left transition */
.fade-in-left-enter-active,
.fade-in-left-leave-active {
  transition: all 0.3s ease;
}

.fade-in-left-enter,
.fade-in-left-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
.card-footer .textarea-field.disabled {
  opacity: 0.7; /* or any other value to make it slightly grey */
  pointer-events: none; /* disable pointer events */
  background-color: rgb(199, 199, 199);
}
</style>
