/**
 * In this file we build the application specific translator.
 *
 */
import { createTranslator, createTranslatorLanguage } from '@prospective/lithium';

import de_CH from './strings/de_CH.js';
import de_DE from './strings/de_DE.js';
import en_US from './strings/en_US.js';
import gsw_CH from './strings/gsw_CH.js';

export interface LanguageList {
  [key: string]: {
    label: string;
    country: string;
  };
}

export const availableLanguages: LanguageList = {
  de_CH: {
    label: 'Deutsch',
    country: 'Schweiz',
  },
  de_DE: {
    label: 'Deutsch',
    country: 'Deutschland',
  },
  en_US: {
    label: 'English',
    country: 'United States',
  },
  gsw_CH: {
    label: 'Schwiizertüütsch',
    country: 'Schwiiz',
  },
};

const navLang = navigator.language.replace('-', '_');
const navLangLanguage = navLang.split('_')[0];
let defaultLanguage = 'en_US';

// try direct match
if (Object.keys(availableLanguages).indexOf(navLang) > -1) {
  defaultLanguage = navLang;
}

// try machting language only
for (const lang in availableLanguages) {
  if (lang.split('_')[0] === navLangLanguage) {
    defaultLanguage = lang;
    break;
  }
}

export const currentLanguage = window.localStorage.getItem('language') || defaultLanguage;

export function setLanguage(language: string) {
  window.localStorage.setItem('language', language);
  window.location.reload();
}

const translator = createTranslator(currentLanguage, [
  createTranslatorLanguage('de_CH', ['de_DE'], de_CH),
  createTranslatorLanguage('gsw_CH', ['de_CH'], gsw_CH),
  createTranslatorLanguage('de_DE', ['en_US'], de_DE),
  createTranslatorLanguage('en_US', [], en_US),
]);

export default translator;
