<template>
  <div>
    <div class="mt-1">
      <AutosizeTextarea
        :rows="3"
        v-model="baseInput"
        :label="appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_label')"
        :placeholder="appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_placeholder')"
        @input="updateModelValue"
        class="mb-1"
      ></AutosizeTextarea>
      <p>
        <small class="text-muted">{{ appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_hint') }}</small>
      </p>
    </div>
    <div class="mt-3">
      <AutosizeTextarea
        :rows="2"
        v-model="additionalSkills"
        :label="$t('promptsuite.linkedinsearch.additional_skills')"
        :placeholder="$t('promptsuite.linkedinsearch.additional_skills.placeholder')"
        @input="updateModelValue"
        class="mb-1"
      ></AutosizeTextarea>
    </div>
    <div class="mt-3">
      <AutosizeTextarea
        :rows="2"
        v-model="additionalTasks"
        :label="$t('promptsuite.linkedinsearch.additional_tasks')"
        :placeholder="$t('promptsuite.linkedinsearch.additional_tasks.placeholder')"
        @input="updateModelValue"
        class="mb-1"
      ></AutosizeTextarea>
    </div>
    <div class="mt-3">
      <AutosizeTextarea
        :rows="2"
        v-model="otherTitles"
        :label="$t('promptsuite.linkedinsearch.other_titles')"
        :placeholder="$t('promptsuite.linkedinsearch.other_titles.placeholder')"
        @input="updateModelValue"
        class="mb-1"
      ></AutosizeTextarea>
    </div>
    <div class="mt-3">
      <AutosizeTextarea
        :rows="2"
        v-model="exclude"
        :label="$t('promptsuite.linkedinsearch.exclude')"
        :placeholder="$t('promptsuite.linkedinsearch.exclude.placeholder')"
        @input="updateModelValue"
        class="mb-1"
      ></AutosizeTextarea>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PromptsuiteAppConnectResponse } from '@/api/promptsuite';
import AutosizeTextarea from '@/components/form/AutosizeTextarea.vue';
import type { Translator } from '@prospective/lithium';
import { ref, watchEffect } from 'vue';

const props = defineProps<{
  appTranslator: Translator;
  appConn: PromptsuiteAppConnectResponse;
  modelValue: string; // the model value is a json object representing our input
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const baseInput = ref<string>('');
const additionalSkills = ref<string>('');
const additionalTasks = ref<string>('');
const otherTitles = ref<string>('');
const exclude = ref<string>('');

watchEffect(() => {
  try {
    const input = JSON.parse(props.modelValue);
    baseInput.value = input.baseInput ?? '';
    additionalSkills.value = input.additionalSkills ?? '';
    additionalTasks.value = input.additionalTasks ?? '';
    otherTitles.value = input.otherTitles ?? '';
    exclude.value = input.exclude ?? '';
  } catch (e) {
    updateModelValue();
  }
});

function updateModelValue() {
  emit(
    'update:modelValue',
    JSON.stringify(
      {
        baseInput: baseInput.value,
        additionalSkills: additionalSkills.value,
        additionalTasks: additionalTasks.value,
        otherTitles: otherTitles.value,
        exclude: exclude.value,
      },
      null,
      2
    )
  );
}
</script>
