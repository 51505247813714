<template>
  <small class="badge rounded-pill bg-light text-dark d-flex align-items-center py-1 px-2">
    <component v-if="props.icon" :is="props.icon" :size="12" class="me-2" />
    <span class="d-inline-block text-truncate">{{ props.text }}</span>
  </small>
</template>
<script setup lang="ts">
import type { FunctionalComponent } from 'vue';
const props = defineProps<{ text: string; icon?: (props: any) => FunctionalComponent }>();
</script>
