<template>
  <div style="height: 120px"></div>
  <div class="fixed-footer-section shadow">
    <slot />
  </div>
</template>
<script setup lang="ts">
/* ------ THIS COMPONENT DISPLAYS LIST OF ITEMS HORIZONTALLY WITH SCROLL ------ */
const props = withDefaults(defineProps<{ sidebarWidth?: string; height?: string }>(), {
  sidebarWidth: '20rem',
  height: '76px',
});
</script>
<style>
.fixed-footer-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: v-bind('props.height');
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: white;
  z-index: 1;
}
@media (min-width: 768px) {
  .fixed-footer-section {
    left: v-bind('props.sidebarWidth');
  }
}
</style>
