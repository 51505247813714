import type { ResponseData } from '@/api/client';
import type { FeaturePoolMirrorCampaign } from '@/stores/customerFeatures';

import { evaluateObjectPath } from '../string/objectpath';

export function getReferenceNumForJob(
  job: ResponseData,
  featureSettings: FeaturePoolMirrorCampaign | undefined
): string {
  let refnum = job.id;
  if (featureSettings?.override_reference_number) {
    refnum = evaluateObjectPath(job, featureSettings.override_reference_number);
  }

  return refnum ?? 'Unknown';
}
