<template>
  <div>
    <Transition name="slide-fade">
      <div class="card p-5" v-if="completionContent.length > 0 || completionStreamContent.length > 0">
        <div class="recruitingiq-logo">
          <span class="text">RecruitingIQ</span>
        </div>
        <div v-if="completionStreamInProgress">
          <div v-if="completionStreamContent.length === 0">
            <span class="text-muted">Connecting...</span>
          </div>
          <div class="completion-message-mk" v-html="formattedCompletionStreamContent"></div>
        </div>
        <div v-else-if="completionContent.length > 0">
          <div class="result-icons">
            <a href="#" @click="emit('copyOutputToClipboard')"><IconClipboard :size="22"></IconClipboard></a>
            <a href="#" @click="emit('regenerateCompletion')"><IconRefresh :size="22"></IconRefresh></a>
          </div>
          <div class="result-icons right">
            <a href="#" @click="givePositiveFeedback()"><IconThumbUp :size="22"></IconThumbUp></a>
            <a href="#" @click="giveNegativeFeedback()"><IconThumbDown :size="22"></IconThumbDown></a>
          </div>
          <div class="completion-message-mk" v-html="formattedCompletionContent"></div>
        </div>
      </div>
      <div v-else-if="!completionStreamInProgress">
        <OutputEmptyState />
      </div>
    </Transition>
    <Teleport to="body">
      <Modal v-model="feedbackModalVisible">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
            <button type="button" class="btn-close" @click="feedbackModalVisible = false" />
            <div class="modal-status bg-primary" />
            <div class="modal-body text-center py-4">
              <IconMessageReport class="icon mb-2 text-primary icon-lg" />
              <h3>{{ $t('promptsuite.feedback.title') }}</h3>
              <div class="text-muted">
                {{ $t('promptsuite.feedback.subtitle') }}
              </div>
              <div class="form-selectgroup mt-3">
                <label class="form-selectgroup-item">
                  <input
                    type="radio"
                    name="score"
                    :value="1"
                    class="form-selectgroup-input"
                    v-model="feedbackData.score"
                  />
                  <span class="form-selectgroup-label"><IconThumbUp class="icon"></IconThumbUp></span>
                </label>
                <label class="form-selectgroup-item">
                  <input
                    type="radio"
                    class="form-selectgroup-input"
                    name="score"
                    :value="-1"
                    v-model="feedbackData.score"
                  />
                  <span class="form-selectgroup-label"><IconThumbDown class="icon"></IconThumbDown></span>
                </label>
              </div>
              <div class="mt-4">
                <textarea
                  class="form-control"
                  rows="3"
                  :placeholder="$t('promptsuite.feedback.message')"
                  v-model="feedbackData.message"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <div class="w-100">
                <div class="row">
                  <div class="col">
                    <button class="btn w-100" @click="feedbackModalVisible = false">
                      {{ $t('promptsuite.feedback.cancel') }}
                    </button>
                  </div>
                  <div class="col">
                    <LoadingButton class="btn btn-primary w-100" @press="sendFeedback">{{
                      $t('promptsuite.feedback.send')
                    }}</LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { request } from '@/api/client';
import translator from '@/locale/translator';
import { LoadingButton, Modal } from '@prospective/lithium';
import { IconClipboard, IconMessageReport, IconRefresh, IconThumbDown, IconThumbUp } from '@tabler/icons-vue';
import { marked } from 'marked';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import OutputEmptyState from './OutputEmptyState.vue';

const props = defineProps<{
  userInput: string;
  completionContent: string;
  completionStreamContent: string;
  completionStreamInProgress: boolean;
  completionUrl: string;
}>();

const emit = defineEmits<{
  (event: 'regenerateCompletion'): void;
  (event: 'copyOutputToClipboard'): void;
}>();

const toast = useToast();

/* --------------------------------- STATE -------------------------------- */

const feedbackModalVisible = ref(false);
const feedbackId = ref<null | number>(null);
const feedbackData = ref({
  score: 0,
  message: '',
});

/* --------------------------------- COMPUTED -------------------------------- */

const formattedCompletionStreamContent = computed(() => {
  return marked.parse(props.completionStreamContent);
});

const formattedCompletionContent = computed(() => {
  return marked.parse(props.completionContent);
});

/* --------------------------------- METHODS -------------------------------- */

function giveNegativeFeedback() {
  feedbackData.value.score = -1;
  feedbackModalVisible.value = true;
  sendFeedback(() => {}, true); // send feedback instantly, message can be changed later
}

function givePositiveFeedback() {
  feedbackData.value.score = 1;
  feedbackModalVisible.value = false; // no need to show modal for positive feedback
  sendFeedback(() => {
    toast.success(translator.get('promptsuite.feedback.thank_you'));
  }, true);
}

function sendFeedback(stopLoading: () => void, passive = false) {
  var url = props.completionUrl + '/feedback';
  var method = 'POST';
  if (feedbackId.value) {
    method = 'PUT';
    url += '/' + feedbackId.value;
  }

  request(
    method,
    url,
    {},
    {
      score: feedbackData.value.score,
      message: feedbackData.value.message,
      output: props.completionContent,
      input: props.userInput,
    }
  )
    .then((response) => {
      feedbackId.value = response.data.id;
    })
    .finally(() => {
      stopLoading();
      if (!passive) {
        feedbackModalVisible.value = false;
        toast.success(translator.get('promptsuite.feedback.thank_you'));
      }
    });
}

/* --------------------------------- LIFECYCLE -------------------------------- */

watch(
  () => props.completionContent,
  () => {
    feedbackId.value = null;
    feedbackData.value = {
      score: 0,
      message: '',
    };
  }
);
</script>

<style scoped lang="scss">
.completion-message-mk {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  p:last-child {
    margin-bottom: 0;
  }
}

.result-icons {
  position: absolute;
  left: 3.8rem;
  bottom: 2.5rem;

  &.right {
    left: auto;
    right: 2rem;
  }

  a {
    color: #999;
    margin-right: 1rem;

    &:hover {
      color: #333;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
