<template>
  <div style="max-width: 150px">
    <h4 class="mb-2 fw-bold">{{ days - days_left }}/{{ days || '&infin;' }} {{ $t('orders.list.days') }}</h4>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${progress * 100}%`"
        :aria-valuenow="days - days_left"
        :aria-valuemin="days"
        :aria-valuemax="0"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Order } from '@/types/types';

const props = defineProps<{ order: Order }>();
const { days, days_left, progress } = props.order.duration || { days: 0, days_left: 0, progress: 0 };
</script>
<style>
@media (max-width: 1400px) {
  .list .progress {
    display: none;
  }
}
</style>
