<template>
  <div class="d-flex flex-row gap-2">
    <div
      :class="`layout-item gap-1 ${layout.value === props.modelValue ? 'active' : ''}`"
      v-for="layout in props.layouts"
      :key="layout.value"
      @click="emit('update:modelValue', layout.value)"
    >
      <component :is="layout.icon" :size="18" />
      <p class="m-0">{{ layout.label }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { FunctionalComponent } from 'vue';
export interface ListLayoutsProps {
  layouts: { label: string; value: string; icon: (props: any) => FunctionalComponent }[];
  modelValue: string;
}

const props = defineProps<ListLayoutsProps>();
const emit = defineEmits(['update:modelValue']);
</script>
<style scoped>
.layout-item {
  color: var(--tblr-muted);
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  cursor: pointer;
  font-weight: 600;
}
.layout-item.active {
  color: black;
  border-bottom: 2px solid black;
  pointer-events: none;
}
.layout-item:hover {
  color: rgba(0, 0, 0, 0.7);
}
</style>
