<template>
  <div>
    <label class="form-label">{{ $t('promptsuite.sidebar.language') }}</label>
    <div class="form-selectgroup">
      <label class="form-selectgroup-item" v-for="item in availableLocales" :key="item.locale">
        <input
          type="radio"
          class="form-selectgroup-input"
          name="locale-selector"
          :value="item.locale"
          :checked="item.locale === props.modelValue"
          @change="handleLocaleChange(item.locale)"
        />
        <span class="form-selectgroup-label"><img :src="item.icon" alt="flag" width="20" height="20" /></span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import FlagIconCH from '@/assets/countries/ch.svg';
import FlagIconDE from '@/assets/countries/de.svg';
import FlagIconUS from '@/assets/countries/us.svg';
import FlagIconIT from '@/assets/countries/it.svg';
import FlagIconFR from '@/assets/countries/fr.svg';

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const availableLocales = [
  {
    locale: 'de_CH',
    icon: FlagIconCH,
  },
  {
    locale: 'de_DE',
    icon: FlagIconDE,
  },
  {
    locale: 'en_US',
    icon: FlagIconUS,
  },
  {
    locale: 'it_CH',
    icon: FlagIconIT,
  },
  {
    locale: 'fr_CH',
    icon: FlagIconFR,
  },
];

function handleLocaleChange(locale: string) {
  emit('update:modelValue', locale);
}
</script>

<style lang="scss" scoped></style>
