<template>
  <div class="horizontal-wrapper gap-3">
    <slot />
  </div>
</template>
<script setup lang="ts">
/* ------ THIS COMPONENT DISPLAYS LIST OF ITEMS HORIZONTALLY WITH SCROLL ------ */
const props = withDefaults(defineProps<{ maxWidth?: string; maxHeight?: string }>(), { maxHeight: '80px' });
</script>
<style>
.horizontal-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  padding-bottom: 0.5rem;
}
.horizontal-wrapper > * {
  flex: 0 0 auto;
  position: relative;
  padding: 0;
  /* will prevent extra tall items to cause trouble */
  max-width: v-bind('props.maxWidth');
  max-height: v-bind('props.maxHeight');
}
</style>
