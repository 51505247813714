<template>
  <div>
    <!-- <div class="form-floating">
      <textarea
        ref="textarea"
        class="form-control"
        autocomplete="off"
        v-bind="$attrs"
        :value="props.modelValue"
        @input="onInput"
      ></textarea>
      <label for="floating-input">{{ attrs.label }}</label>
    </div> -->
    <label class="form-label">{{ attrs.label }}</label>
    <textarea
      ref="textarea"
      class="form-control mt-2"
      autocomplete="off"
      v-bind="$attrs"
      :value="props.modelValue"
      @input="onInput"
      @focus="emit('focus')"
      @blur="emit('blur')"
    ></textarea>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { useAttrs } from 'vue';

/* --------------------------------- PROPS & EMITS -------------------------------- */

const props = defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'focus'): void;
  (event: 'blur'): void;
}>();

/* --------------------------------- STATE -------------------------------- */

const attrs = useAttrs();
const textarea = ref<null | HTMLTextAreaElement>(null);

onMounted(() => {
  nextTick(() => {
    adjustHeight();
  });
});

const adjustHeight = () => {
  if (textarea.value) {
    textarea.value.style.height = 'auto';
    textarea.value.style.height = textarea.value.scrollHeight + 'px';
  }
};

const onInput = (event: Event) => {
  adjustHeight();
  emit('update:modelValue', (event.target as HTMLTextAreaElement).value);
};
</script>

<style scoped>
.form-control {
  overflow-y: hidden;
  resize: none;
}
</style>
