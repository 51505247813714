import { currentLanguage } from './translator';
import { defaultCurrency } from '@/config/app';

/**
 * Formats the given currency value into a string
 * example:
 *   1000 -> 10 CHF
 */
export function formatCurrency(value: number, currency?: string): string {
  const floatingValue = (value ?? 0) / 100 ?? 0;
  return floatingValue.toLocaleString(currentLanguage.replace('_', '-'), {
    style: 'currency',
    currency: currency ?? defaultCurrency,
  });
}

/**
 * Get currency sign
 * example:
 *   'EUR' -> '€'
 */
export function getCurrencySign(currency: string): string {
  if (currency === 'CHF') return 'CHF';
  if (currency === 'USD') return '$';
  if (currency === 'EUR') return '€';
  return '$';
}
