<template>
  <Teleport :to="target" v-if="target" :disabled="!target || disabled">
    <slot></slot>
  </Teleport>
</template>

<script setup lang="ts">
/* ------------------- THIS COMPONENT USES VUE 3 TELEPORT ------------------- */
/* ---------- HOWEVER IT WAITS FOR THE EXPECTED CONTAINER TO APPEAR --------- */
import { ref, onMounted } from 'vue';

const props = defineProps<{ to: string; disabled?: boolean }>();

const target = ref<Element>();

onMounted(() => {
  const observer = new MutationObserver((mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type !== 'childList') continue;
      const el = document.querySelector(props.to);
      if (!el) continue;
      target.value = el;
      observer.disconnect();
      break;
    }
  });
  observer.observe(document, { childList: true, subtree: true });
  return () => observer.disconnect();
});
</script>
