<template>
  <div class="stats-graph gap-1">
    <div
      :style="`height: ${bar.percentage}%`"
      v-tooltip="{ content: bar.tooltip, html: true }"
      v-for="(bar, index) in bars"
      :class="maxValue && maxValue === bar.value ? 'leading' : ''"
      :key="rand + index"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

export interface StatsGraphDataItem {
  label: string;
  count: number;
}

const rand = Math.random();
const minBarPercentage = 20;
const remainingPercentage = 100 - minBarPercentage;
const props = defineProps<{ data: StatsGraphDataItem[]; maxVal?: number }>();

const renderTooltip = (dataItem: StatsGraphDataItem) =>
  `<p class="text-center m-0">
		${dataItem.label ? `<span>${dataItem.label}</span><br/>` : ''}
		<span class="fw-bold">${dataItem.count}</span>
	</p>`;

const calcPercentage = (item: StatsGraphDataItem) => ({
  value: item.count,
  // Division with 0 is not allowed, we must set it to 1 in order to work
  percentage: (item.count / (maxValue.value || 1)) * remainingPercentage + minBarPercentage,
  tooltip: renderTooltip(item),
});

const maxValue = computed(() => {
  // we are checking if it's 0 again in the template
  // if it is, we don't emphasize the bar as max
  if (!props.data.length) return 0;
  if (props.maxVal) return props.maxVal;
  return Math.max(...props.data.map((d) => d.count));
});
const bars = computed(() => props.data.map((val) => calcPercentage(val)).reverse());
</script>
<style scoped>
.stats-graph {
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
  height: 50px;
  padding: 5px 0;
}
@media (min-width: 1200px) {
  .stats-graph {
    width: auto;
  }
}
.stats-graph > div {
  width: 10px;
  height: 10%;
  height: 10%;
  background-color: var(--tblr-border-color);
  border-radius: 2px;
}
.stats-graph > div:hover {
  background-color: var(--tblr-muted);
}
.stats-graph > div.leading {
  background-color: #1bd264;
}
</style>
