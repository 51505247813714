<template>
  <div class="progress dz-file">
    <div
      :class="`progress-bar dz-file-progress ${isError ? 'bg-danger' : ''}`"
      :style="{ width: `${props.uploadable.percentage}%` }"
      role="progressbar"
      :aria-valuenow="props.uploadable.percentage"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div class="dz-file-icon">
        <IconPhotoX v-if="isError" :size="20" />
        <IconPhoto v-else :size="20" />
      </div>

      <div class="dz-file-text">
        <p :class="`w-100 mb-1 ${isUploaded ? 'text-truncate' : ''}`">{{ name }}</p>
        <p class="w-100 mb-1">{{ fileSize }} Kb</p>
      </div>
      <IconCircleX v-if="isUploaded" role="button" class="dz-file-remove" :stroke-width="1.2" @click="emit('remove')" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconPhoto, IconPhotoX, IconCircleX } from '@tabler/icons-vue';
import { computed } from 'vue';
import type { UploadableFile } from './utils';

const props = defineProps<{ uploadable: UploadableFile }>();

const emit = defineEmits(['remove']);
const isUploaded = computed(() => 100 === props.uploadable.percentage);
const isError = computed(() => false === props.uploadable.status);

/* -------------------------- Preview calculations -------------------------- */
const name = computed(() => {
  const { description, hash } = props.uploadable.file;
  return description || hash || 'Unknown';
});
const fileSize = computed(() => {
  const { size } = props.uploadable.file;
  return (size ? size / 1024 : 0).toFixed(2);
});
</script>
<style scoped>
.dz-file {
  /* Reset progress-bar height */
  height: auto;
  padding: 0;
}
.dz-file .dz-file-progress {
  overflow: visible;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}
.dz-file .dz-file-progress > * {
  text-align: initial;
  line-height: initial;
}
.dz-file .dz-file-progress .dz-file-icon {
  flex: 0 0 auto;
  margin-right: 1em;
  display: block;
}
.dz-file .dz-file-text {
  min-width: 0;
  padding-right: 5px;
  margin-right: auto;
}
.dz-file .dz-file-remove {
  align-self: center;
  min-width: 24px;
}
.dz-file .dz-file-remove:hover {
  cursor: pointer;
}
</style>
