<template>
  <div class="row">
    <div class="col-8">
      <label class="form-label required">{{ $t('datepicker.startdate') }}</label>
      <Datepicker
        v-model="internalStart"
        text-input
        :auto-apply="true"
        :enable-time-picker="false"
        :min-date="dateTomorrow"
        :format="'dd/MM/yyyy'"
        :placeholder="$t('datepicker.campaign_start')"
      ></Datepicker>
    </div>
    <div class="col-4">
      <label class="form-label required">{{ $t('datepicker.days') }}</label>
      <LiFormInput
        v-model.number="numberOfDays"
        :class="[minimumNumberOfDays > numberOfDays ? 'is-invalid' : '']"
        type="number"
        :min="minimumNumberOfDays"
      ></LiFormInput>
    </div>
    <div class="col-12 text-muted mt-1">
      <strong class="float-end">{{ formatDateForUI(internalEnd) }}</strong>
      <small>{{ $t('datepicker.date_help') }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref, inject, onMounted } from 'vue';
import type { WritableComputedRef, Ref } from 'vue';

import { FormInput as LiFormInput } from '@prospective/lithium';

import { addDays, startOfTomorrow, parseISO } from 'date-fns';
import { PJAS_DATE_FORMAT, formatDateTime } from '../../../filters/dateFormat';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import type { OrderCreationData } from './orderCreation';

const emit = defineEmits(['update:startdate', 'update:enddate']);
const props = defineProps({
  startdate: { type: String, required: true },
  enddate: { type: String, required: true },
  defaultCampaignLength: { type: Number, required: true },
});

// some config
let pjasDateFormat = PJAS_DATE_FORMAT;

const orderCreation = inject<Ref<OrderCreationData>>('orderCreation') as Ref<OrderCreationData>;

onMounted(() => {
  numberOfDays.value = orderCreation.value.minimum_duration_days;
});
// mininum number of days the campaign should run for
const minimumNumberOfDays = computed(() => {
  return orderCreation.value.minimum_duration_days;
});

// takes the string from the prop and allows it
// to be handled as a date object locally
const internalStart: WritableComputedRef<Date> = computed({
  get(): Date {
    return parseISO(props.startdate);
  },
  set(value: any): void {
    if (!value) {
      emit('update:startdate', null);
    }
    emit('update:startdate', formatDateTime(value, pjasDateFormat));
  },
});

const internalEnd: WritableComputedRef<Date> = computed({
  get(): Date {
    return parseISO(props.enddate);
  },
  set(value: any): void {
    if (!value) {
      emit('update:enddate', null);
    }
    emit('update:enddate', formatDateTime(value, pjasDateFormat));
  },
});

function formatDateForUI(date: Date): string {
  return formatDateTime(date, 'dd/MM/yyyy');
}

// number of days the campaign should run for
const numberOfDays = ref();
const dateTomorrow = startOfTomorrow();
recalculateCurrentNumberOfDays();

function recalculateCurrentNumberOfDays() {
  let days = Math.round((internalEnd.value.getTime() - internalStart.value.getTime()) / (1000 * 60 * 60 * 24));
  numberOfDays.value = days;
}

// watch the number of days to update the enddate
watch(numberOfDays, (newValue) => {
  internalEnd.value = addDays(internalStart.value, newValue);
  formatDateForUI(internalEnd.value);
});
watch(internalStart, (newValue) => {
  internalEnd.value = addDays(newValue, numberOfDays.value);
});

watch(minimumNumberOfDays, (newValue) => {
  if (numberOfDays.value) {
    numberOfDays.value = newValue;
  }
  formatDateForUI(internalEnd.value);
});
</script>
