import { request } from './client';
import type { QueryParams } from './client';
import type { ImageObject, OrderMedia, ResponseOrderImage } from '@/types/types';

export function extractDataFromURL(url) {
  return request(
    'POST',
    '/v1/account/order/extract_from_url',
    {},
    {
      url,
    }
  );
}

export function calculateOrderLimits(productIds: number[], params: QueryParams = {}) {
  return request('POST', '/v1/account/order/calculate_limits', params, {
    selected_product_ids: productIds,
  });
}

export async function fetchAutocompleteFields(fields: string, params: object = {}): Promise<string[]> {
  const response = await request('GET', `/v1/account/orders/autocomplete/${fields}`, params);
  return response.data;
}

export async function getOrderImages(orderId: number | string): Promise<OrderMedia> {
  const response: { data: ResponseOrderImage[] } = await request('GET', `/v1/account/order/${orderId}/images`);
  const media: OrderMedia = { companyLogo: [], images: [] };
  if (Array.isArray(response.data)) {
    response.data.forEach((img) => {
      if (img.type === 'company_logo') media.companyLogo.push(img.image);
      else media.images.push(img.image);
    });
  }

  return media;
}

export async function changeOrderImages(
  orderId: number,
  media: OrderMedia,
  options: { filterSelected?: boolean; setDefaultCompanyLogo?: boolean } = {}
): Promise<ResponseOrderImage> {
  const { companyLogo, images } = media;
  const body: unknown[] = [];
  companyLogo.forEach((obj: ImageObject) =>
    body.push({
      hash: obj.hash,
      type: 'company_logo',
      sequence: 0,
      set_default_company_logo: !!options.setDefaultCompanyLogo,
    })
  );
  images.forEach((obj: ImageObject) =>
    !options.filterSelected || obj.selected
      ? body.push({
          hash: obj.hash,
          type: 'image',
          sequence: 0,
        })
      : null
  );
  const response = await request('POST', `/v1/account/order/${orderId}/images`, {}, body);
  return response.data;
}
