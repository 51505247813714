export default {
  language: 'Language',
  sign_out: 'Sign out',
  sign_in: 'Sign in',
  email_address: 'Email address',
  password: 'Password',
  'app.something_wrong': 'Something went wrong. Try again later',
  'app.init_loading': 'Preparing application',
  'app.init_failure': 'PJAS LEAD could not be initialized. Please try again later or contact support.',
  'login.title': 'Sign in with your {app} account',
  'forgot_password.title': 'Forgot password',
  'reset_password.title': 'Reset your {app} password',
  'reset_password.reset_button': 'Request Reset Email',
  'reset_password.email_sent': 'A reset link was sent if an account is associated with that address',
  'reset_password.wrong_link': 'Wrong reset password link. Try again later',
  'login.remember_me': 'Remember me on this device',
  'login.password_placeholder': 'Your password',
  'nav.home': 'Home',
  'nav.orders': 'Orders',
  'nav.cvanalyzer': 'CV Assistant',
  'nav.active_poolmirror_campaign': 'Portfolio Campaign',
  'home.see_all_orders': 'See all orders',
  'home.see_all_orders_heading': "Let's See Your Order!",
  'home.see_all_orders_subheading': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
  'home.see_all_poolmirror': 'See Campaign',
  'home.see_all_poolmirror_heading': 'Your Portfolio Campaign',
  'home.see_all_poolmirror_subheading': 'Your Portfolio Slot Subscription with Utilization and Campaigns at a Glance.',
  'home.create_order': 'Create an order',
  'home.create_order_heading': "Don't have an order yet?",
  'home.create_order_subheading': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
  'portfolio.crawled_jobs': 'Crawled jobs',
  'portfolio.campaign_slots': 'Campaign slots',
  'portfolio.slot_usage': 'Slot usage',
  'portfolio.subscription': 'Subscription',
  'portfolio.subscription_ends_in': 'ends',
  'portfolio.available_jobs': 'Available jobs',
  'portfolio.show_all_slots': 'Show all available slots',
  'portfolio.job_search_placeholder': 'Press "/" to search for jobs',
  'search.generic_placeholder': 'Press "/" to search',
  'cvanalyzer.list.title': 'Analyzed CVs',
  'is_loading.generic': 'Loading data...',
  'sort.direction': 'Sort direction',
  'sort.field.relevance': 'Relevance',
  'sort.field.created_at': 'Created at',
  'orders.list.sort.title': 'Order title',
  'orders.layout.list': 'List',
  'orders.layout.grid': 'Grid',
  'orders.list.title': 'orders',
  'orders.create_button': 'Create an order',
  'orders.list.total_clicks': 'Total Clicks',
  'orders.list.impressions': 'Impressions',
  'orders.list.days': 'Days',
  'orders.list.worldwide': 'Worldwide',
  'orders.pagination.page': 'Page',
  'orders.pagination.prev': 'Previous',
  'orders.pagination.next': 'Next',
  'orders.filters.company_name': 'Company Name',
  'orders.filters.creator': 'Creator',
  'orders.filters.location': 'Workspace Location',
  'orders.filters.language': 'Language',
  'orders.filters.reset_filters': 'Reset Filters',
  'orders.filters.apply': 'Apply',

  'btn.continue': 'Continue',
  'btn.back': 'Back',
  'btn.duplicate': 'Duplicate',
  'btn.delete': 'Delete',
  'common.message': 'Message',
  'common.step': 'Step',
  'common.of': 'of',
  'common.comment': 'Comment',
  'common.done': 'Done',
  'common.workspace': 'Workspace',
  'common.company': 'Company',
  'common.title': 'Title',
  'common.job': 'Job',
  'common.dates': 'Dates',
  'common.duration': 'Duration',
  'common.impressions': 'impressions',
  'common.clicks': 'clicks',
  'datepicker.startdate': 'Start date',
  'datepicker.select_start': 'Select campaign start',
  'datepicker.date_help': 'Please specify how long you want your campaign to run.',
  'datepicker.days': 'Days',
  'dropzone.drop_browse': 'Drop or browse files',
  'dropzone.drop_msg': 'Drop the files',

  'orders.create.wizard': 'Wizard',
  'orders.create.form.disabled': 'Publication URL required',
  'orders.create.full_form': 'Full-form',
  'orders.create.title_create': 'Create new order',
  'orders.create.title_modify': 'Modify and create new order',
  'orders.create.title_confirm': 'Please confirm your new order',
  'orders.wizard.intro.heading': 'Hi there',
  'orders.wizard.intro.subheading':
    "Let me help you to create your programmatic order. I'm still learning, but I will try to help you gather the required information and analyze your data.",
  'orders.wizard.intro.job_posting': 'your job posting',
  'orders.wizard.steps.url': 'Online Publication URL',

  'orders.wizard.intro.url_enter': 'Enter the URL of your job posting.',
  'orders.wizard.intro.url_help':
    'The page behind this URL should exclusively contain the job posting you are trying to promote.',
  'orders.wizard.products.title': 'Product Selection',
  'orders.wizard.products.subtitle': 'How would you like to push your job posting?',
  'orders.wizard.products.products': 'Products',
  'orders.wizard.products.product': 'Product',
  'orders.wizard.products.min_budget': 'Min Budget',
  'orders.wizard.products.notice': 'Please select at least one product.',
  'orders.wizard.products.total_min_budget': 'Total Min Budget',
  'orders.wizard.products.min_duration': 'Minimum Duration',
  'orders.wizard.products.daily_min_budget': 'Daily Min Budget',

  'orders.wizard.platforms.title': 'Platform Selection',
  'orders.wizard.platforms.subtitle':
    'Where should we publish your job posting? Please select the platforms you want to use.',
  'orders.wizard.platforms.platforms': 'Platforms',

  'orders.wizard.analytics.title': 'Analysing Job Data',
  'orders.wizard.analytics.subtitle':
    'We are currently analysing your online job posting. This can take a few seconds to complete...',
  'orders.wizard.analytics.campaign_duration': 'Campaign Duration',
  'orders.wizard.analytics.campaign_budget': 'Campaign Budget',

  'orders.wizard.analytics.total_budget': 'Total Budget',
  'orders.wizard.analytics.daily': 'Daily',
  'orders.wizard.analytics.budget_help':
    'Please specify the budget for your campaign. With a running time of 30 days, the minimum budget is',
  'orders.wizard.analytics.crawling': 'Crawling job posting',
  'orders.wizard.analytics.connecting': 'Connecting to Robot',

  'orders.wizard.details.title': 'Job Details',
  'orders.wizard.details.subtitle': 'Please check and complete details of your posting.',
  'orders.wizard.details.job_title': 'Job title / position',
  'orders.wizard.details.job_title_help':
    "Provide your job title. Ideally without the workload '80-100%'. Try to keep it short and concise as space is limited.",
  'orders.wizard.details.company_name': 'Company name',
  'orders.wizard.details.company_name_help':
    'The name of the company hiring the position. This will be displayed publicly.',
  'orders.wizard.details.workload_min': 'Workload min',
  'orders.wizard.details.workload_max': 'Workload max',
  'orders.wizard.details.location': 'Workplace location',
  'orders.wizard.details.location_help':
    'The location of the workplace. This will be displayed publicly and used for geotargeting in some cases.',

  'orders.wizard.media.title': 'Media',
  'orders.wizard.media.subtitle': 'Optionally, upload media for this order',
  'orders.wizard.media.company_logo': 'Company logo',
  'orders.wizard.media.order_images': 'Order Images',

  'orders.wizard.remarks.title': 'Remarks',
  'orders.wizard.remarks.subtitle':
    'Let us know if you have any special requests or remarks. We will get back to you as soon as possible.',
  'orders.wizard.remarks.msg_placeholder': 'Please do...',

  'orders.create.form.ref_number': 'Reference No.',
  'orders.create.form.ref_number_help':
    'This is the reference number of the order in your system. Will be generated automatically if left empty.',
  'orders.create.form.images_empty': 'Order has no images',
  'orders.create.form.products_empty': 'Order has no products chosen',
  'orders.create.form.save_confirm': 'Save & Confirm',

  'orders.details.title': 'Order Details',
  'orders.details.clicks': 'Total Clicks',
  'orders.details.impressions': 'Total Impressions',
  'orders.details.cost_per_click': 'Cost Per-Click',
  'orders.details.cpc_waiting': 'Waiting for more data',
  'orders.details.total_cost': 'Total Cost',
  'orders.details.better_than_yesterday': 'better than yesterday',
  'orders.details.worse_than_yesterday': 'worse than yesterday',
  'orders.details.remarks.empty': 'There are no remarks for this order',
  'orders.details.previews.empty': 'There are no previews for this order',
  'orders.details.channels': 'Channels',
  'orders.details.preview': 'Preview',
  'orders.details.channels.empty': 'No channels found for this order',
  'orders.details.delete_order.title': 'Delete this order?',
  'orders.details.delete_order.description':
    'Are your sure you want to delete this order? This action cannot be undone.',

  'nav.promptsuite': 'RecruitingIQ',
  'promptsuite.apps.title': '{filter} Apps',
  'promptsuite.apps.subtitle': 'Available AI Apps',
  'promptsuite.show_advanced_options': 'Show Advanced Options',
  'promptsuite.additional_user_instructions': 'Additional Instructions',
  'promptsuite.additional_user_instructions_placeholder':
    'Example: Write it so simply that a 4-year-old could understand it.',
  'promptsuite.additional_user_instructions.hint':
    'You can provide additional instructions here that the AI should follow while generating the text.',
  'promptsuite.generate': 'Generate',
  'promptsuite.sidebar.title': 'Input',
  'promptsuite.sidebar.language': 'Language',

  'promptsuite.apps.filter.all': 'All',
  'promptsuite.apps.filter.jobposting': 'Job Posting',
  'promptsuite.apps.filter.socialmedia': 'Social Media',
  'promptsuite.apps.filter.activesourcing': 'Active Sourcing',
  'promptsuite.apps.filter.interview': 'Interview',
  'promptsuite.apps.filter.personnelconsultant': 'Personaldienstleister',
  'promptsuite.apps.filter': 'Filter',
  'promptsuite.empty_hint': 'Please fill in your information in the right panel.',

  'promptsuite.welcome.title': 'Willkommen bei RecruitingIQ',
  'promptsuite.welcome.subtitle': 'Startseite',
  'promptsuite.welcome.button_to_apps': 'Direkt zu den Apps',

  'promptsuite.linkedinsearch.additional_skills': 'Additional Skills',
  'promptsuite.linkedinsearch.additional_skills.placeholder': 'Example: Salesforce, CRM, Solutionssales',

  'promptsuite.linkedinsearch.additional_tasks': 'Additional Tasks',
  'promptsuite.linkedinsearch.additional_tasks.placeholder': 'Example: Lead generation, Customer acquisition',

  'promptsuite.linkedinsearch.other_titles': 'Additional Job Titles',
  'promptsuite.linkedinsearch.other_titles.placeholder': 'Example: Sales Manager, Sales Representative',

  'promptsuite.linkedinsearch.exclude': 'Excluded Jobs, Skills, Tasks or Titles',
  'promptsuite.linkedinsearch.exclude.placeholder': 'Example: Call Center, Customer Service',

  'promptsuite.linkedinsearch.open_in_linkedin': 'Open in LinkedIn',

  'promptsuite.feedback.title': 'App Feedback',
  'promptsuite.feedback.subtitle': `Your feedback is valuable to us. If you encountered any problems with the app or the content it
  produces, please share your experience so we can enhance our service.`,
  'promptsuite.feedback.message': 'Please describe your experience...',
  'promptsuite.feedback.send': 'Send Feedback',
  'promptsuite.feedback.cancel': 'Cancel',
  'promptsuite.feedback.thank_you': 'Thank you for your feedback!',

  'eula.accept': 'Accept EULA',
  temperature: 'Temperature',
  'temperature.p1': `The temperature parameter controls the randomness or creativity of the model's output. It is a
  hyperparameter that influences the probability distribution of the tokens (words or characters)
  generated by the model.`,
  'temperature.p2': `A higher temperature value (e.g., 1.0 or above) will make the model generate more diverse and creative responses, 
  but at the risk of becoming less coherent or accurate. A lower temperature value (e.g., 0.1 or below) will make the model produce more 
  focused, conservative, and deterministic outputs, sticking closely to the patterns it has seen in its training data.`,
  'template.hint': `The template modifies the writing style of the job posting. Choose a template that best fits your job's
  audience for optimal results.`,

  'settings.accounts.title': 'Accounts',
  'settings.accounts.subtitle': 'Settings / Accounts',
  'settings.accounts.description': 'Create and manage your accounts',

  'settings.companies.title': 'Companies',
  'settings.companies.subtitle': 'Settings / Companies',
  'settings.companies.description': 'Create and manage your companies',

  'company.images': 'Company Images',
  'company.images.title': 'Company Images',
  'company.images.logo.title': 'Company Logo',
  'company.images.logo.subtitle': 'Optionaly, upload media for this company',
  'company.images.media.title': 'Company Media',
  'company.images.media.subtitle': 'Optionaly, upload media for this company',
  'company.images.media.success_update_message': 'Company images updated',
  'company.details': 'Company Details',
  'company.profile': 'Profile',
  'company.details.personal_details': 'Personal Details',
  'company.details.name': 'Company Name',
  'company.details.homepage': 'Company Homepage',
  'company.details.address': 'Address',
  'company.details.address_street': 'Street',
  'company.details.address_city': 'City',
  'company.details.address_zip': 'Zip Code',
  'company.details.address_country': 'Country',
  'company.create.button': 'Create Company',
  'company.save.button': 'Save Company',

  'nav.settings': 'Settings',
  'settings.title': 'Settings',
  'settings.subtitle': 'Settings / Overview',

  'account.settings': 'Account Settings',
  'account.create.button': 'Create Account',

  'account.profile': 'Profile',
  'account.profile.save': 'Save Account',
  'account.profile.personal_details': 'Personal Details',
  'account.profile.firstname': 'First Name',
  'account.profile.lastname': 'Last Name',
  'account.profile.email': 'Email Address',
  'account.profile.email_help': "This is the email address you use for login, and it's how we will contact you.",
  'account.profile.admin.title': 'Admin Permission',
  'account.profile.admin.text': 'Is Admin User?',
  'account.profile.admin_help':
    'By making this account an admin, it will bypass all permission checks. Additionally, only admin accounts have the ability to edit, create, and delete other accounts.',

  'account.password': 'Password & Security',
  'account.password.password': 'Password',
  'account.password.change': 'Change Password',
  'account.password.new_field': 'New Password',
  'account.password.repeat_field': 'Repeat Password',
  'account.password.set_new': 'Set New Password',
  'account.password.passwords_need_to_match': 'The given passwords need to match.',
  'account.password.change_help': 'Please enter your new password twice to ensure there are no typos.',
  'account.password.explain': "You can change your account's password at any point in time.",

  'account.experience': 'Experience',
  'account.experience.give_feedback': 'Give Feedback',

  'order.status.to_be_confirmed': 'Unconfirmed',
  'order.status.confirmed': 'Confirmed',
  'order.status.running': 'Running',
  'order.status.finished': 'Finished',

  'order.chat.no_messages': 'There are not messages yet.',
};
