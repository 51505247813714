<template>
  <div ref="prompti" :style="logoStyle" class="prompti">
    <div class="eye left" ref="promptiLeftEye" :style="eyeStyle"></div>
    <div class="eye right" ref="promptiRightEye" :style="eyeStyle"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from 'vue';

import promptiBackground from '@/assets/logo/prompts/prompts_we.png';

/* --------------------------------- STATE -------------------------------- */

const eyeSize = ref(1.0);
const eyeLookX = ref(0.0);
const eyeLookY = ref(0.0);

let eyeLookCursorX = 0.0;
let eyeLookCursorY = 0.0;
let eyeTargetSize = 1.0;
const eyeTargetX = ref(0.0);
const eyeTargetY = ref(0.0);
const eyeMode = ref<'cursor' | 'center' | 'circle' | 'left'>('center');

const prompti = ref<null | HTMLDivElement>(null);
const promptiLeftEye = ref<null | HTMLDivElement>(null);
const promptiRightEye = ref<null | HTMLDivElement>(null);

/* --------------------------------- COMPUTED -------------------------------- */

const logoStyle = computed(() => {
  return {
    width: '300px',
    height: '300px',
    backgroundImage: 'url(' + promptiBackground + ')',
    backgroundSize: 'cover',
  };
});

const eyeStyle = computed(() => {
  const eyeTranslation = (1.0 - eyeSize.value) * 23;

  let x = eyeLookX.value * eyeTranslation + eyeLookX.value * 3;
  let y = eyeLookY.value * eyeTranslation + eyeLookY.value * 3;

  return {
    transform: 'scale(' + eyeSize.value + ') translate(' + x + 'px, ' + y + 'px)',
    transformOrigin: 'center',
  };
});

/* --------------------------------- METHODS -------------------------------- */

function handleMouseMove(e: MouseEvent) {
  // calculate the mouse positions offset from the center of the prompti
  const promptiRect = prompti.value?.getBoundingClientRect();

  if (!promptiRect) return;

  const mouseX = e.clientX - promptiRect.left - promptiRect.width / 2;
  const mouseY = e.clientY - promptiRect.top - promptiRect.height / 2;

  // calulate a normalized vector from the mouse position to the center of the prompti
  const mouseLength = Math.sqrt(mouseX * mouseX + mouseY * mouseY);
  const mouseNormX = mouseX / mouseLength;
  const mouseNormY = mouseY / mouseLength;

  // apply the normalized vector to the eye look direction
  eyeLookCursorX = mouseNormX;
  eyeLookCursorY = mouseNormY;
}

let lastTimestep = 0;
function handleFrameStep(timeStep) {
  // get an alpha value for constant animation speed
  const alpha = (timeStep - lastTimestep) / (1000 / 60);
  lastTimestep = timeStep;

  // handle mode specifc targets
  if (eyeMode.value === 'center') {
    eyeTargetX.value = 0.0;
    eyeTargetY.value = 0.0;
  } else if (eyeMode.value === 'left') {
    eyeTargetX.value = -1.0;
    eyeTargetY.value = 0.0;
  } else if (eyeMode.value === 'cursor') {
    eyeTargetX.value = eyeLookCursorX;
    eyeTargetY.value = eyeLookCursorY;
  } else if (eyeMode.value === 'circle') {
    const circleRadius = 0.5;
    const circleSpeed = 0.002;

    eyeTargetX.value = Math.cos(timeStep * circleSpeed) * circleRadius;
    eyeTargetY.value = Math.sin(timeStep * circleSpeed) * circleRadius;
  }

  const eyeLookDeltaX = eyeTargetX.value - eyeLookX.value;
  const eyeLookDeltaY = eyeTargetY.value - eyeLookY.value;
  const eyeSizeDelta = eyeTargetSize - eyeSize.value;

  eyeLookX.value += eyeLookDeltaX * alpha * 0.1;
  eyeLookY.value += eyeLookDeltaY * alpha * 0.1;
  eyeSize.value += eyeSizeDelta * alpha * 0.1;

  window.requestAnimationFrame(handleFrameStep);
}

function determineEyeMode() {
  const eyeModeScale = Math.floor(Math.random() * 3);

  switch (eyeModeScale) {
    case 0:
      eyeTargetSize = 1.0;
      break;
    case 1:
      eyeTargetSize = 0.8;
      break;
    case 2:
      eyeTargetSize = 0.6;
      break;
  }

  const eyeModePick = Math.floor(Math.random() * 4);
  switch (eyeModePick) {
    case 0:
      eyeMode.value = 'center';
      break;
    case 1:
      eyeMode.value = 'left';
      break;
    case 2:
      eyeMode.value = 'cursor';
      break;
    case 3:
      eyeMode.value = 'circle';
      break;
  }
}

/* --------------------------------- LIFECYCLE -------------------------------- */
let targetPickIntervalId;

onMounted(() => {
  document.addEventListener('mousemove', handleMouseMove);
  window.requestAnimationFrame(handleFrameStep);
  targetPickIntervalId = setInterval(determineEyeMode, 3000);
});

onUnmounted(() => {
  document.removeEventListener('mousemove', handleMouseMove);
  clearInterval(targetPickIntervalId);
});
</script>

<style scoped lang="scss">
.prompti {
  position: relative;
  .eye {
    border-radius: 100px;
    background-color: #2a3658;
    width: 23px;
    height: 23px;
    position: absolute;

    &.left {
      // left: 121.5px;
      // top: 144px;
      left: 111.5px;
      top: 132px;
    }

    &.right {
      // left: 170px;
      // top: 144px;
      left: 160px;
      top: 132px;
    }
  }
}
</style>
