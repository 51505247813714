import { request } from './client';

import type { ResponseData } from './client';
import type { EulaAssignment, User } from '@/types/types';
import type { WSInfo } from './ws';

export interface SignedInUserResponse {
  authenticated: boolean;
  signed_in: boolean;
  data: User | null;
  ws: WSInfo;
}

/**
 * Returns the currently logged in user (if any)
 */
export function fetchSignedInUser(): Promise<SignedInUserResponse> {
  return request('GET', '/v1/account/session', {});
}

/**
 * Logs the user in with the given credentials
 */
export function requestUserSignIn(identifier: string, secret: string): Promise<ResponseData> {
  return request(
    'POST',
    '/v1/account/session/signin',
    {},
    { identifier, secret },
    {
      skipDefaultErrorHandler: true,
    }
  );
}

/**
 * Fetches the next unaccepted EULA of the current customer
 */
export function fetchUnacceptedEula(): Promise<{ data: EulaAssignment }> {
  return request('GET', '/v1/account/eula/unaccepted', {});
}

/**
 * Accepts the given EULA
 */
export function acceptEula(eulaAssignemntId: number): Promise<{ message: string }> {
  return request('POST', `/v1/account/eula/accept`, {}, { customer_eula_id: eulaAssignemntId });
}

/**
 * Send Forgot Email password
 */
export function requestUserResetPassword(email: string): Promise<{ message: string }> {
  return request('POST', `/v1/account/password_reset/send_email`, {}, { email });
}
