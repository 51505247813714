<template>
  <div class="d-flex flex-column">
    <div class="border rounded overflow-hidden">
      <a class="order-ad-preview" :href="directlink" target="_blank">
        <div v-if="isLoading" :class="`avatar avatar-lg ${fullSize ? 'w-100 h-100' : ''}`">
          <div class="spinner-border" />
        </div>
        <img
          v-else
          :class="`avatar avatar-lg ${fullSize ? 'w-100 h-100' : ''}`"
          :src="orderBanner ? orderBanner?.proxy.url_thumbnail : '/svgs/orderBanner.svg'"
        />
      </a>
    </div>
    <div class="banner-actions d-flex justify-content-around mt-2" v-if="props.showActions">
      <a :href="directlink" target="_blank"><IconLink :size="20" :stroke-width="1.2" /></a>
      <IconLayersSubtract
        v-if="featureStore.featureCanCreateEditOrder"
        :size="20"
        :stroke-width="1.2"
        @click.stop="duplicateOrder"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconLink, IconLayersSubtract } from '@tabler/icons-vue';
import { ref, onMounted } from 'vue';
import type { Image, Order } from '@/types/types';
import { useRouter } from 'vue-router';
import { fetchUrlPreviewImage } from '@/api/media';
import { useRequestQueue } from '@/api/useQueue';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

const props = withDefaults(defineProps<{ order: Order; showActions?: boolean; fullSize?: boolean }>(), {
  showActions: true,
  fullSize: false,
});
const router = useRouter();
const featureStore = useCustomerFeaturesStore();
// Use the async queue instance from the parent
const { addToQueue, isFetching } = useRequestQueue();

const orderBanner = ref<Image>();
const isLoading = ref(true);
const { directlink } = props.order || {};

onMounted(() => {
  addToQueue(() =>
    fetchUrlPreviewImage(directlink)
      .then((res) => {
        if (res.image) orderBanner.value = res.image;
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        isLoading.value = false;
      })
  );
});

const duplicateOrder = () => {
  window.localStorage.setItem('orderId', JSON.stringify(props.order.id));
  router.push({ name: 'order.create' });
};
</script>
<style scoped>
.banner-actions svg:hover {
  cursor: pointer;
  stroke: darkgray;
}
.order-ad-preview {
  display: block;
  position: relative;
  z-index: 1;
}
.order-ad-preview > img {
  min-width: 90px;
}
.order-ad-preview:hover::after {
  display: block;
}
.order-ad-preview::after {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  backdrop-filter: brightness(0.9);
  z-index: 2;
}
</style>
