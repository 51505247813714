class WSClient {
  private socket: Promise<WebSocket> | null;
  private RETRIES = 6;
  protected wshost: string;
  protected token: string;
  protected actionHandlers: { [key: string]: ((msg: any) => void)[] } = {};

  constructor(wsInfo: WSInfo) {
    // modify the given hostname to the
    // pjas ws by convention

    this.wshost = wsInfo.url;
    this.token = wsInfo.token;
    this.socket = null;
    this.actionHandlers = {};
  }

  newClientPromise(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      const wsClient = new WebSocket(this.wshost);

      wsClient.onopen = () => {
        console.info('WebSocket connected');
        wsClient.send(JSON.stringify({ action: 'auth', token: this.token }));
        // this timeout is a really dump hack that still might not work
        // dependeing on how slow the server responds. I havent been able to figure out
        // how to ensure the auth message as finished processing on the server side.
        // This really only matters for the first connection, so it should mostly be fine.
        setTimeout(() => {
          resolve(wsClient);
        }, 300);
      };
      wsClient.onmessage = this.onSocketMessage.bind(this);
      wsClient.onerror = (error) => {
        console.log('WebSocket error', error);
        reject(error);
      };
      wsClient.onclose = (e) => {
        console.log('WebSocket closed. Reason: ', e.reason);
        this.RETRIES--; // finite re-connection requests
        if (!this.RETRIES) return;

        setTimeout(() => {
          console.log('Trying to reconnect');
          this.socket = this.newClientPromise();
        }, 1000);
      };
    });
  }

  connect() {
    if (!this.socket) {
      this.socket = this.newClientPromise();
    }
    return this.socket;
  }

  async sendAction(action: string, parameters: { [key: string]: unknown } = {}) {
    parameters.action = action;
    const connection = await this.connect();
    connection.send(JSON.stringify(parameters));
  }

  onSocketMessage(message: { data: string }) {
    const msg: { message: string; action: string } = JSON.parse(message.data);

    // no action nothing to handle
    if (!(msg?.action ?? false)) {
      return;
    }

    if (msg.action === 'log') {
      console.log(msg?.message ?? 'Unknown WS Message');
      return;
    }

    for (const index in this.actionHandlers[msg.action] ?? []) {
      const callback = this.actionHandlers[msg.action][index];
      callback(msg);
    }
  }

  registerActionHandler(action: string, callback: (msg: any) => void) {
    if (typeof this.actionHandlers[action] === 'undefined') {
      this.actionHandlers[action] = [];
    }

    if (!this.actionHandlers[action].includes(callback)) {
      this.actionHandlers[action].push(callback);
    }
  }

  unregisterActionHandler(action: string, callback: (msg: any) => void) {
    if (typeof this.actionHandlers[action] === 'undefined') {
      this.actionHandlers[action] = [];
    }

    if (this.actionHandlers[action].includes(callback)) {
      this.actionHandlers[action].splice(this.actionHandlers[action].indexOf(callback), 1);
    }
  }
}

export default WSClient;

export type WSClientType = WSClient;

export interface WSInfo {
  hostname: string;
  path: string;
  token: string;
  url: string;
}
