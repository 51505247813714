import { request } from './client';
import type { QueryParams } from './client';

export function fetchPoolmirrorCrawledJobs(campaignId: number) {
  return request('GET', '/v1/account/poolmirror/' + campaignId + '/crawler/jobs', { page_size: 1000 });
}

export function setPoolmirrorCrawledJobPriority(campaignId: number, jobId: number, priority: number) {
  return request(
    'POST',
    '/v1/account/poolmirror/' + campaignId + '/crawler/jobs/' + jobId + '/priority',
    {},
    { priority }
  );
}

export function fetchPoolmirrorPublications(campaignId: number, params: QueryParams = {}) {
  return request('GET', '/v1/account/poolmirror/' + campaignId + '/publications', params);
}
