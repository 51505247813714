/**
 * Generates a user acronym for the given string
 *
 * @param username - A string representing the name
 * @returns - The string as an acronym
 */
export function getAcronym(username: string): string {
  const usernameParts = username.replace(/[ |_|-|.]/i, '.').split('.');
  const acronym = (usernameParts[0]?.[0] ?? '?') + (usernameParts[1]?.[0] ?? '');
  return acronym;
}
