<template>
  <div class="wizard-console">
    <TransitionGroup name="fade">
      <div v-for="(item, i) in timelineReversed" :key="i" class="wizard-console-item">
        <div class="row align-items-center">
          <div class="col text-muted">
            <component :is="item.icon" stroke-width="1" class="me-2"></component> {{ item.title }}
          </div>
          <div class="col-auto">
            <IconCheckbox v-if="i !== 0 || isDone" stroke-width="1" class="text-green"></IconCheckbox>
            <div v-else class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { IconCheckbox } from '@tabler/icons-vue';

export type ScanTimelineItem = {
  title: string;
  icon: any;
};

const props = defineProps<{
  timeline: ScanTimelineItem[];
  isDone: boolean;
}>();

// reverse the timeline
const timelineReversed = computed(() => {
  return Object.values(props.timeline as ScanTimelineItem[]).reverse();
});
</script>

<style scoped>
.wizard-console-item {
  margin: 5px;
  border-radius: 3px;
  background-color: #fff;
  padding: 6px 12px;
  border-bottom: 1px solid #d3d3d3;
}
</style>
