<template>
  <HeaderNavbar>
    <LiPageHeader
      class="order-details-page w-full me-4"
      :title="$t('orders.details.title')"
      :has-filters="false"
      :has-back-button="true"
      route-back-to="/dashboard/orders"
    >
      <div class="d-flex flex-row gap-3" v-if="featureStore.featureOrderManagment">
        <IconLayersSubtract class="btn btn-icon btn-dark" :size="20" :stroke-width="1.2" @click.stop="duplicateOrder" />
        <IconTrash
          class="btn btn-icon btn-danger"
          :size="20"
          :stroke-width="1.2"
          @click="deleteConfirmationDialog = true"
        />
      </div>
      <!-- End of filters -->
    </LiPageHeader>
  </HeaderNavbar>
  <!-- DISPLAY ORDER -->
  <div v-if="order" class="container-lead">
    <div class="row row-deck">
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.impressions')"
        :info="order.stats_impressions_total || 0"
        :info_description="$t('common.impressions')"
        :graph_data="impresStatsData"
        :footer="improvementClickText"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.clicks')"
        :info="order.stats_clicks_total || 0"
        :info_description="$t('common.clicks')"
        :graph_data="clickStatsData"
        :footer="improvementImpresText"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :class="cpc_text.length > 10 ? 'info-muted' : ''"
        :title="$t('orders.details.cost_per_click')"
        :info="cpc_text"
        v-if="displayCost"
      />
      <OrderInfoCard
        class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0"
        :title="$t('orders.details.total_cost')"
        :info="orderBudget"
        :show_graph="false"
        v-if="displayCost"
      />
    </div>
    <div class="card mt-3 p-1">
      <div class="card-body row">
        <OrderBanner class="col-4 col-lg-3 h-100" :order="order!" :full-size="true" :show-actions="false" />
        <div class="col-8 col-lg-9 d-flex flex-column gap-y-3 ps-3 gap-3">
          <div class="d-flex align-items-center">
            <p class="d-inline-block text-muted m-0" style="font-size: 16px">{{ order?.provider_job_id }}</p>
            <IconInfoCircle class="ms-1" :size="16" />
          </div>
          <h2 class="text-truncate m-0">{{ order?.title }}</h2>
          <div class="d-flex gap-3">
            <p class="m-0">
              <span class="text-muted">{{ $t('common.dates') }}:&nbsp;</span>
              <span class="fw-bold text-nowrap">
                {{ formatDate(order?.startdate) }} - {{ formatDate(order?.enddate) }}
              </span>
            </p>
            <p class="m-0">
              <span class="text-muted">{{ $t('common.duration') }}:&nbsp;</span>
              <span class="fw-bold">{{ order.duration?.days }} {{ $t('orders.list.days') }}</span>
            </p>
          </div>
          <div class="d-flex align-items-center gap-3">
            <OrderCompanyLogo :company="order.company?.data" />
            <h3 class="fw-bold m-0">{{ order.company_name }}</h3>
          </div>
          <div>
            <OrderPills :order="order!" />
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3 class="mb-3">
          {{ $t('orders.wizard.remarks.title') }} <IconInfoCircle class="text-muted ms-1" :size="18" />
        </h3>
        <p v-if="order.comment">
          {{ order.comment }}
        </p>
        <p v-else class="text-muted">{{ $t('orders.details.remarks.empty') }}</p>
      </div>
    </div>
    <div class="card mt-3" id="previews" v-if="allowPreviews">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <h3 class="col-6">
            {{ $t('orders.details.preview') }} <IconInfoCircle class="text-muted ms-1" :size="18" />
          </h3>
          <div class="col-6 d-flex justify-content-end">
            <button
              :class="`text-capitalize ms-1 btn ${previewType === key ? 'btn-primary' : 'btn-ghost-secondary'}`"
              v-for="(value, key) in previewTypes"
              :key="key"
              @click="previewType = key"
            >
              {{ value }}
            </button>
          </div>
        </div>
        <div class="previews-wrapper">
          <OrderBannerPreview :key="preview.key" v-for="preview in previewsToShow" :preview="preview" />
        </div>
        <div v-if="!previewsToShow.length" class="text-muted">{{ $t('orders.details.previews.empty') }}</div>
      </div>
    </div>
    <div class="card mt-3" id="channels">
      <div class="card-body">
        <h3 class="mb-3">{{ $t('orders.details.channels') }} <IconInfoCircle class="text-muted ms-1" :size="18" /></h3>
        <p v-if="!products?.length" class="text-muted">{{ $t('orders.details.channels.empty') }}</p>
        <HorizontalList v-else>
          <div
            class="d-flex justify-content-center gap-2 border rounded py-2 px-3"
            v-for="channel in products"
            :key="channel.name"
          >
            <span
              class="avatar avatar-xs bg-transparent"
              :style="`background-image: url('${channel.image?.data?.proxy.url_thumbnail}')`"
            ></span>
            <span class="fw-bold">{{ channel.name }}</span>
          </div>
        </HorizontalList>
      </div>
    </div>
    <!-- CHAT -->
    <ChatCard :channel="chatChannel" :key="chatChannel" class="mt-3 chat-card-block mb-4" id="chat" />
  </div>
  <!-- PLACEHOLDER -->
  <div v-else class="container-lead mt-4">
    <div class="mb-3 row row-deck">
      <div class="col-12 col-lg-6 col-xxl-3 mb-3 mb-xxl-0" v-for="i in [1, 2, 3, 4]" :key="i">
        <div class="card card-sm">
          <div class="card-body">
            <div class="placeholder placeholder-xs col-7 mb-3"></div>
            <div class="d-flex justify-content-between">
              <div class="placeholder placeholder-sm col-6" />
              <div class="placeholder placeholder-xs col-3 ms-auto" />
            </div>
            <div class="hr my-2" />
            <div class="placeholder placeholder-xs col-5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-sm">
      <div class="row g-0">
        <div class="col-3">
          <div class="ratio ratio-4x3 card-img-start placeholder"></div>
        </div>
        <div class="col">
          <div class="card-body">
            <div class="placeholder placeholder-xs col-5 mb-3"></div>
            <div class="placeholder placeholder col-8 mb-3"></div>
            <div class="placeholder placeholder-xs col-5"></div>
            <div class="col-8">
              <div class="placeholder placeholder-xs col-1 me-2" />
              <div class="placeholder placeholder-xs col-1 me-2" />
              <div class="placeholder placeholder-xs col-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SafeTeleport to="#orderDetailsSubmenu">
    <div class="dropdown-menu-columns">
      <div class="dropdown-menu-column">
        <a role="button" class="dropdown-item" @click="scrollToId(sub.refId)" v-for="sub in subMenu" :key="sub.label">
          <span class="nav-link-icon d-md-none d-lg-inline-block"> </span>
          <span class="nav-link-title nav-text-size">{{ sub.label }}</span>
        </a>
      </div>
    </div>
  </SafeTeleport>
  <LiConfirmationDialog
    v-model:dialogShow="deleteConfirmationDialog"
    confirmButtonLabel="Delete"
    @onContinue="deleteOrder"
  >
    <h1 class="mb-3">{{ $t('orders.details.delete_order.title') }}</h1>
    <p>{{ $t('orders.details.delete_order.description') }}</p>
  </LiConfirmationDialog>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import { PageHeader as LiPageHeader, ConfirmationDialog as LiConfirmationDialog } from '@prospective/lithium';
import { IconLayersSubtract, IconTrash, IconInfoCircle } from '@tabler/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { request } from '@/api/client';
import ChatCard from '@/components/chat/ChatCard.vue';
import OrderInfoCard from '@/components/orders/OrderInfoCard.vue';
import OrderCompanyLogo from '@/components/orders/order_elements/OrderCompanyLogo.vue';
import OrderPills from '@/components/orders/order_elements/OrderPills.vue';
import OrderBannerPreview from '@/components/orders/OrderBannerPreview.vue';
import { defaultCurrency, defaultLocale } from '@/config/app';
import type { Order, OrderProduct, OrderPreview } from '@/types/types';
import OrderBanner from '@/components/orders/order_elements/OrderBanner.vue';
import { formatDate } from '@/filters/dateFormat';
import HorizontalList from '@/components/generic/HorizontalList.vue';
import { scrollToId } from '@/func/scrollTo';
import SafeTeleport from '@/components/generic/SafeTeleport.vue';
import { extractStats } from '@/func/statsCache';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import translator from '@/locale/translator';
import { formatCurrency } from '@/locale/format';

const route = useRoute();
const router = useRouter();
const toast = useToast();

const subMenu = [
  { refId: 'previews', label: 'Preview' },
  { refId: 'channels', label: 'Channel' },
  { refId: 'chat', label: 'Chat' },
];

const orderId = route.params.id as string;
const featureStore = useCustomerFeaturesStore();

const order = ref<Order>();
const products = ref<OrderProduct[]>();
const previews = ref<OrderPreview[]>();
const previewType = ref<string>('adform');
const isLoading = ref(true);
const deleteConfirmationDialog = ref(false);

const chatChannel = computed(() => `job:${orderId}.public`);
const previewsToShow = computed(() => (previews.value || []).filter((p) => p.key.includes(previewType.value)));
const displayCost = computed(() => featureStore.featureOrderManagment?.display_cost);
const allowPreviews = computed(() => featureStore.featureOrderManagment?.allow_previews);
const clickStatsData = computed(() => extractStats(order.value?.stats_cache || {}, 'clicks'));
const improvementClickText = computed(() =>
  calculateImprovement(clickStatsData.value.at(0)?.count, clickStatsData.value.at(1)?.count)
);
const impresStatsData = computed(() => extractStats(order.value?.stats_cache || {}, 'impressions'));
const improvementImpresText = computed(() =>
  calculateImprovement(impresStatsData.value.at(0)?.count, impresStatsData.value.at(1)?.count)
);
const cpc_text = computed(() => {
  if (
    order.value?.stats_cost_per_click &&
    featureStore.featureOrderManagment &&
    (featureStore.featureOrderManagment.cpc_t ?? 500) >= order.value.stats_cost_per_click
  )
    return formatCurrency(order.value.stats_cost_per_click, order.value.budget_currency);
  return translator.get('orders.details.cpc_waiting');
});

const previewTypes = computed(() => {
  const allTypes = { adform: 'Display', instagram: 'Instagram', facebook: 'Facebook', google: 'Google' };
  if (!previews.value?.length) return {};
  for (const pKey in allTypes) {
    if (!previews.value.find((p) => p.key.includes(pKey))) delete allTypes[pKey];
  }
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  if (!allTypes[previewType.value]) previewType.value = Object.keys(allTypes)[0];
  return allTypes;
});

onMounted(async () => {
  try {
    // If use has no feature permission, redirect to home
    if (!featureStore.featureOrderManagment) return router.replace({ name: 'home' });

    const fetchOrder = await request('GET', `/v1/account/order/${orderId}`, {
      resolve: 'company,company.logo_image,stats_cache,creator',
    });
    order.value = fetchOrder?.data;
    isLoading.value = false;
  } catch (error) {
    toast.error('Something went wrong. Please try again later');
  }

  request('GET', `/v1/account/order/${orderId}/products`, {
    resolve: 'image',
  }).then((res) => (products.value = res?.data));
  request('GET', `/v1/account/order/${orderId}/campaign_previews`).then((res) => (previews.value = res?.data));
});

const orderBudget = computed(() => {
  const currency = order.value?.budget_currency ?? defaultCurrency;
  const budget = (order.value?.budget_gross_total ?? 0) / 100 ?? 0;
  return budget.toLocaleString(defaultLocale, {
    style: 'currency',
    currency,
  });
});

const duplicateOrder = () => {
  window.localStorage.setItem('orderId', orderId);
  router.push({ name: 'order.create' });
};

const deleteOrder = async () => {
  const response = await request('DELETE', `/v1/account/order/${orderId}`, {}, {});

  if (response?.success) {
    router.push({
      name: 'orders.list',
    });
  }
  toast.success('The order has been deleted successfully', { icon: 'ti ti-check' });
};
const calculateImprovement = (today: number = 0, yesterday: number = 0) => {
  if ((!today && !yesterday) || today === yesterday)
    return `0% ${translator.get('orders.details.better_than_yesterday')}`;
  const increased = today > yesterday;
  const percentage = Math.ceil(100 * (increased ? (today - yesterday) / today : (yesterday - today) / yesterday));
  return `${percentage}% ${translator.get(
    increased ? 'orders.details.better_than_yesterday' : 'orders.details.worse_than_yesterday'
  )}`;
};
</script>
<style scoped>
.previews-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 306px);
  gap: 12px;
}
.chat-card-block :deep(.chat) {
  max-height: 400px;
  overflow-y: auto;
}
</style>
