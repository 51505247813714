/**
 * Allows you to access a property of an object by a path string, e.g. 'foo.bar.baz'
 */
export function evaluateObjectPath(obj: object, path: string): any {
  const accessParts = path.split('.');
  let accessObject = obj;
  for (const part of accessParts) {
    if (accessObject === undefined) {
      break;
    }
    accessObject = accessObject[part] ?? undefined;
  }

  return accessObject;
}
