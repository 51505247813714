import { request } from './client';
import type { QueryParams } from './client';
import type { PromptsuiteApp } from '@/types/types';

export interface PromptsuiteRelayMessage<T> {
  type: string;
  data: T;
}

export type PRMCompletionStream = PromptsuiteRelayMessage<{ chunk: string }>;
export type PRMCompletion = PromptsuiteRelayMessage<{ content: string }>;

export interface PromptsuiteAppConnectResponse {
  relay_url: string;
  relay_token: string;
  data: PromptsuiteApp;
}

export interface PromptsuiteConversationMessageVersion {
  role: string;
  creator: string;
  message: string;
  created_at: string;
}

export interface PromptsuiteConversationMessage {
  role: string;
  creator: string;
  message: string;
  has_been_modified: boolean;
  created_at: string;
  versions: PromptsuiteConversationMessageVersion[];
}

export interface PromptsuiteAppInteractionResponse {
  conversation: PromptsuiteConversationMessage[];
  data: PromptsuiteApp;
}

export function fetchPromptSuiteAppCategories(
  params: QueryParams = {}
): Promise<{ data: { category: string; count: number }[] }> {
  return request('GET', '/v1/account/promptsuite/apps/categories', params);
}

export function fetchPromptSuiteAppConn(
  appId: number,
  params: QueryParams = {}
): Promise<PromptsuiteAppConnectResponse> {
  return request('GET', '/v1/account/promptsuite/apps/' + appId + '/connect', params);
}

export function getPromptSuiteAppUrl(appId: number, relayToken: string): string {
  return '/v1/account/promptsuite/apps/' + appId + '/' + relayToken;
}

export function interactWithPromptSuiteApp(
  appId: number,
  relayToken: string,
  body: QueryParams
): Promise<PromptsuiteAppInteractionResponse> {
  return request('POST', getPromptSuiteAppUrl(appId, relayToken) + '/interact', {}, body);
}
