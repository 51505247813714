import type { StatsGraphDataItem } from '@/components/orders/order_elements/OrderBarGraph.vue';
import { formatDate } from '@/filters/dateFormat';
import type { Order } from '@/types/types';
import { differenceInCalendarDays } from 'date-fns';

export function extractStats(stats_cache: Partial<Order['stats_cache']> | null, stat: string): StatsGraphDataItem[] {
  const min7Items = Array.from({ length: 7 }, () => ({ label: '', count: 0 }));
  if (!stats_cache) return min7Items;
  const { last_build, data: statsData } = stats_cache;
  const existingStats: StatsGraphDataItem[] = [];
  if (statsData && last_build && !Array.isArray(statsData)) {
    Object.keys(statsData).forEach((statDate) => {
      const diff = differenceInCalendarDays(new Date(), new Date(statDate));
      if (diff < 0) return;
      existingStats[diff] = { label: formatDate(statDate), count: statsData[statDate][stat] };
    });
  }
  return [...existingStats, ...min7Items].filter((a) => a).slice(0, 7);
}
