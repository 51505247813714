<template>
  <div :style="{ width: searchBarWidth + 'px' }">
    <form autocomplete="off" novalidate @submit.prevent>
      <div class="input-icon">
        <span class="input-icon-addon">
          <IconSearch :stroke-width="1" :size="18" />
        </span>
        <input
          ref="searchInput"
          type="text"
          class="form-control lead-main-search"
          :placeholder="placeholder"
          v-model="internalValue"
          @focus="handleFocus"
        />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import translator from '@/locale/translator.js';

import { IconSearch } from '@tabler/icons-vue';

// Props
export interface Props {
  searchBarWidth?: number;
  placeholder?: string;
  modelValue: string;
}

// declare the defaults
const props = withDefaults(defineProps<Props>(), {
  searchBarWidth: 300,
  placeholder: translator.get('search.generic_placeholder'),
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string | null): void;
  (event: 'focus'): void;
}>();

const searchInput = ref<HTMLInputElement | null>(null);

let internalValue = computed({
  get() {
    return props.modelValue ?? '';
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyDown);
});

function handleFocus() {
  emit('focus');
}

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === '/') {
    searchInput.value?.focus();
    event.preventDefault();
  }
}
</script>

<style></style>
