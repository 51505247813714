<template>
  <div class="page page-center" style="height: 100vh">
    <div class="container container-lead py-4">
      <div class="row align-items-center g-4">
        <div class="col-lg">
          <div class="container-tight">
            <div class="text-center mb-4">
              <a href="." class="navbar-brand navbar-brand-autodark mb-4">
                <img :src="defaultLogoTextPath" class="d-md-none" style="max-width: 200px" />
              </a>
            </div>
            <div class="card card-md">
              <div class="card-body">
                <h2 class="h2 mb-4">{{ $t('login.title', { app: appNameShort }) }}</h2>

                <div class="mb-0">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      @change="setLanguage(($event.target as HTMLInputElement).value)"
                      v-model="currentLanguage"
                    >
                      <option v-for="(language, code) in availableLanguages" :key="code" :value="code">
                        {{ language.label }} - {{ language.country }}
                      </option>
                    </select>
                    <label for="floatingSelect">{{ $t('language') }}</label>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form action="./" method="get" autocomplete="off" novalidate>
                  <div class="mb-3">
                    <li-form-input
                      v-model="email"
                      type="email"
                      placeholder="your@email.com"
                      :label="$t('email_address')"
                      :errors="signInErrors?.identifier ?? []"
                    />
                  </div>
                  <div class="mb-2">
                    <label class="form-label">
                      {{ $t('password') }}
                      <span class="form-label-description" v-if="allowPasswordReset">
                        <router-link :to="{ name: 'forgotPassword' }">{{ $t('forgot_password.title') }}?</router-link>
                      </span>
                    </label>
                    <li-form-input
                      v-model="password"
                      type="password"
                      :placeholder="$t('login.password_placeholder')"
                      :errors="signInErrors?.secret ?? []"
                      class="mb-1"
                    ></li-form-input>
                  </div>
                  <div class="mb-2">
                    <label class="form-check">
                      <input type="checkbox" class="form-check-input" />
                      <span class="form-check-label">{{ $t('login.remember_me') }}</span>
                    </label>
                  </div>
                  <div class="form-footer">
                    <li-loading-button class="btn btn-primary w-100" @press="signInUser"
                      >{{ $t('sign_in') }} <IconLogin :size="17" :stroke-width="2" class="ms-2"></IconLogin>
                    </li-loading-button>
                  </div>
                </form>
              </div>
              <div class="hr-text" v-if="allowPMSIDLogin">or</div>
              <div class="card-body" v-if="allowPMSIDLogin">
                <div class="row">
                  <div class="col">
                    <a href="#" class="btn w-100"> Login with Prospective ID </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-muted mt-3" v-if="allowRegistration">
              Don't have account yet? <a href="./sign-up.html" tabindex="-1">Sign up</a>
            </div>
          </div>
        </div>
        <div class="col-lg d-none d-lg-block">
          <div class="position-relative">
            <AnimatedPromptLogo></AnimatedPromptLogo>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end px-5">
      <img src="./../assets/logo/pms_logo_light.svg" height="30" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { FormInput as LiFormInput, LoadingButton as LiLoadingButton } from '@prospective/lithium';
import { useToast } from 'vue-toastification';

import { availableLanguages, setLanguage, currentLanguage } from '@/locale/translator';

import { IconLogin } from '@tabler/icons-vue';
import { appNameShort, defaultLogoTextPath } from '@/config/app';
import { requestUserSignIn } from '@/api/auth';
import type { ClientError } from '@/api/client';
import { useUserStore } from '@/stores/user';
import AnimatedPromptLogo from '@/components/brand/AnimatedPromptLogo.vue';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

const router = useRouter();
const toast = useToast();
const userStore = useUserStore();
const featureStore = useCustomerFeaturesStore();

const allowPasswordReset = true;
const allowRegistration = false;
const allowPMSIDLogin = false;

const email = ref('');
const password = ref('');
const signInErrors = ref<{ [index: string]: string[] }>();

function signInUser(stopLoading: () => void) {
  requestUserSignIn(email.value, password.value)
    .then(() => {
      userStore.fetchCurrent().then(async () => {
        await featureStore.fetchFeatures();
        router.push({ name: 'home' });
      });
    })
    .catch((error: ClientError) => {
      signInErrors.value = error.responseJson.errors ?? [];
      toast.error(error.responseJson.message ?? 'Invalid credentials');
    })
    .finally(() => {
      stopLoading();
    });
}
</script>

<style scoped>
.page {
  background-color: #1a191e;
  background-color: #1b1c1e;
}
</style>
