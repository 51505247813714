import type { IChatChannel, IChatMessage, IAttachment } from '@/types/chat.js';
import { request } from './client';

export function fetchChatMessages(
  chatChannel: string,
  after?: number
): Promise<{ data: IChatMessage[]; chat_channel: IChatChannel }> {
  return request('GET', `/v1/account/chat/${chatChannel}`, { after, limit: 50 });
}

export function sendChatMessage(
  chatChannel: string,
  message: string,
  attachments: IAttachment[]
): Promise<{ data: IChatMessage }> {
  return request('POST', `/v1/account/chat/${chatChannel}`, undefined, { message, attachments });
}

export function fetchChatMentions(chatChannel: string) {
  return request('GET', `/v1/account/chat/${chatChannel}/mentions`, undefined);
}

export const uploadAttachment = (attachment: FormData): Promise<{ data: IAttachment }> => {
  return request('POST', `/v1/account/attachments`, { class: 0 }, attachment);
};
