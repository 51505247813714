<template>
  <div :style="previewWrapperStyle" v-if="props.preview">
    <iframe :style="previewStyle" :srcdoc="renderHTML" ref="previewFrame" scrolling="no" />
    <button
      v-if="allowFullscreen"
      class="btn btn-sm btn-ghost-primary btn-icon"
      v-tooltip="'Fullscreen'"
      @click="openFull = true"
    >
      <IconArrowsMaximize :size="14" />
    </button>
    <div class="preview-overlay" @click="openFull = true" />
    <Transition>
      <div
        class="fullscreen-preview"
        v-if="openFull"
        @click.stop="openFull = false"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      >
        <iframe
          :style="previewFullStyle"
          :srcdoc="renderHTML"
          ref="previewFrame"
          :allowfullscreen="true"
          scrolling="no"
        />
        <IconX :size="20" role="button" class="close-button" />
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import type { OrderPreview } from '@/types/types';
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { request } from '@/api/client';
import { debounce } from '@prospective/lithium';
import { IconArrowsMaximize, IconX } from '@tabler/icons-vue';

const size = 300; // fixed number

const previewWrapperStyle: any = {
  width: `${size + 6}px`,
  height: `${size + 6}px`,
  backgroundColor: 'rgb(241, 245, 249)',
  padding: '3px',
  position: 'relative',
};

const props = withDefaults(defineProps<{ preview: OrderPreview; allowFullscreen?: boolean }>(), {
  allowFullscreen: true,
});
const renderHTML = ref();
const previewFrame = ref();
const openFull = ref(false);
const fullSize = ref(Math.min(window.innerWidth, window.innerHeight));

onMounted(() => {
  request('GET', `/v1/account/campaigns/${props.preview.campaignId}/preview/${props.preview.key}`).then(
    (res) => (renderHTML.value = res)
  );
  window.addEventListener('resize', debounce(getFullSize, 600));
});

onUnmounted(() => {
  window.removeEventListener('resize', debounce(getFullSize, 600));
});

const scale = computed(() => {
  if (props.preview) {
    const scaleX = size / props.preview.width;
    const scaleY = size / props.preview.height;
    return Math.min(scaleX, scaleY, 1);
  }
  return 1;
});

const fullScale = computed(() => {
  const scaleX = fullSize.value / props.preview.width;
  const scaleY = fullSize.value / props.preview.height;
  return Math.min(scaleX, scaleY, 0.95);
});

const previewStyle = computed(() => {
  const { width, height } = props.preview;
  return {
    backgroundColor: 'white',
    transform: `scale(${scale.value})`,
    transformOrigin: '0 0',
    width: `${width}px`,
    height: `${height}px`,
    marginTop: `${(size - height * scale.value) / 2}px`,
    marginLeft: `${(size - width * scale.value) / 2}px`,
  };
});

const previewFullStyle = computed(() => {
  const { width, height } = props.preview;
  return {
    backgroundColor: 'white',
    transform: `scale(${fullScale.value})`,
    transformOrigin: '0 0',
    width: `${width}px`,
    height: `${height}px`,
  };
});
const getFullSize = () => {
  fullSize.value = Math.min(window.innerWidth, window.innerHeight);
};

watch(openFull, (val) => {
  // Stop background scroll
  document.body.style.overflow = val ? 'hidden' : 'auto';
});
</script>
<style scoped>
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.preview-overlay {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.fullscreen-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1031;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
