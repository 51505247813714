<template>
  <div v-if="featureStore.featurePromptsuite" class="h-100">
    <HeaderNavbar>
      <LiPageHeader
        :title="appConn ? appTranslator.get('promptapp:' + appConn?.data.id + '.lead_title') : 'Connecting...'"
        :subtitle="$t('nav.promptsuite')"
        :has-filters="false"
        :route-back-to="{ name: 'promptsuite.apps' }"
      >
      </LiPageHeader>
    </HeaderNavbar>
    <div class="promptapp-container" v-if="appConn">
      <div
        class="promptapp-sidebar animate__animated animate__fadeInLeft animate__faster"
        :class="{ expanded: editorExpanded }"
      >
        <div class="">
          <h2 class="mb-3">{{ $t('promptsuite.sidebar.title') }}</h2>
          <div class="text-muted mb-3">
            {{ appTranslator.get('promptapp:' + appConn?.data.id + '.lead_description') }}
          </div>
          <InputOneShot
            :app-translator="appTranslator"
            :app-conn="appConn"
            v-model="userInput"
            v-if="appConn.data.configuration.lead_layout_type === 'oneshot'"
            @focus="userInputFocus = true"
            @blur="userInputFocus = false"
          ></InputOneShot>
          <InputLinkedinSearch
            :app-translator="appTranslator"
            :app-conn="appConn"
            v-model="userInput"
            v-else-if="appConn.data.configuration.lead_layout_type === 'linkedin'"
          ></InputLinkedinSearch>

          <div>
            <div class="mb-4" v-if="appConn.data.configuration.user_prompt_support_locale ?? false">
              <LocaleSelector v-model="generatorLocale"></LocaleSelector>
            </div>

            <LoadingButton
              @press="performCompletion()"
              class="btn btn-block btn-pill btn-dark btn-lg"
              :is-loading="completionStreamInProgress"
              :disabled="generateButtonDisabled"
              >{{ $t('promptsuite.generate') }} <IconPlayerPlay class="ms-2" :size="22"></IconPlayerPlay
            ></LoadingButton>

            <div v-if="appConn.data.configuration.user_prompt_support_additional ?? false">
              <div class="mt-4" v-if="!toggleAdvancedOptions">
                <a href="#" @click.prevent="toggleAdvancedOptions = !toggleAdvancedOptions"
                  >{{ $t('promptsuite.show_advanced_options') }} <IconChevronDown :size="16"></IconChevronDown
                ></a>
              </div>

              <div v-if="toggleAdvancedOptions" class="mt-3">
                <div class="my-4">
                  <AutosizeTextarea
                    v-model="additionalUserInstructions"
                    :label="$t('promptsuite.additional_user_instructions')"
                    :placeholder="$t('promptsuite.additional_user_instructions_placeholder')"
                    class="mb-1"
                  ></AutosizeTextarea>
                  <p>
                    <small class="text-muted">{{ $t('promptsuite.additional_user_instructions.hint') }}</small>
                  </p>
                  <!-- <label class="form-label">{{ $t('temperature') }} ({{ temperature }})</label>
                <input class="form-range" type="range" v-model="temperature" :min="0.0" :max="2.0" :step="0.1" />
                <small class="text-muted"> {{ $t('temperature.p1') }} <br /><br />{{ $t('temperature.p2') }} </small> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="promptapp-main" :class="{ collapsed: editorExpanded }">
        <div class="container-lead">
          <div class="animate__animated animate__fadeInRight animate__faster">
            <div class="px-5">
              <div class="d-flex justify-content-center">
                <Transition name="slide-fade-down">
                  <div
                    class="card p-4 position-absolute"
                    style="min-width: 300px; z-index: 10; margin-top: -20px"
                    v-if="processingStatus.isProcessing"
                  >
                    <div class="flex-row d-flex align-items-center justify-content-center">
                      <!-- <div class="spinner-border"></div> -->
                      <span class="status" :class="'status-' + processingStatus.statusColor">
                        <span class="status-dot status-dot-animated"></span>
                        {{ processingStatus.message }}
                      </span>
                      <!-- <div class="ms-3">
                      <strong></strong>
                    </div> -->
                    </div>
                    <div class="mt-3">
                      <div class="progress progress-sm">
                        <div
                          class="progress-bar progress-bar-indeterminate"
                          v-if="processingStatus.progress === 0"
                        ></div>
                        <div
                          class="progress-bar"
                          v-else
                          :style="{ width: processingProgressDisplay * 100 + '%' }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>

              <OutputOneShot
                :completion-content="completionContent"
                :completion-stream-content="completionStreamContent"
                :completion-stream-in-progress="completionStreamInProgress"
                :completion-url="getPromptSuiteAppUrl(appConn.data.id, appConn.relay_token)"
                :user-input="userInputDebug"
                @copy-output-to-clipboard="copyOutputToClipboard"
                @regenerate-completion="regenerateCompletion"
              >
              </OutputOneShot>
              <!-- <OutputLinkedinSearch
                :completion-content="completionContent"
                :completion-stream-content="completionStreamContent"
                :completion-stream-in-progress="completionStreamInProgress"
                @copy-output-to-clipboard="copyOutputToClipboard"
                @regenerate-completion="regenerateCompletion"
                v-if="appConn.data.configuration.lead_layout_type === 'linkedin'"
              ></OutputLinkedinSearch> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import {
  createTranslator,
  createTranslatorLanguage,
  LoadingButton,
  PageHeader as LiPageHeader,
} from '@prospective/lithium';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import { useRoute } from 'vue-router';
import {
  fetchPromptSuiteAppConn,
  getPromptSuiteAppUrl,
  interactWithPromptSuiteApp,
  type PRMCompletionStream,
  type PromptsuiteAppConnectResponse,
} from '@/api/promptsuite';

import { io, Socket } from 'socket.io-client';
import AutosizeTextarea from '@/components/form/AutosizeTextarea.vue';
import { IconChevronDown, IconPlayerPlay } from '@tabler/icons-vue';
import { useToast } from 'vue-toastification';
import { currentLanguage } from '@/locale/translator';
import { useUserStore } from '@/stores/user';
import LocaleSelector from './LocaleSelector.vue';
import InputOneShot from './input/InputOneShot.vue';
import InputLinkedinSearch from './input/InputLinkedinSearch.vue';
import OutputOneShot from './output/OutputOneShot.vue';

/* --------------------------------- STATE -------------------------------- */

const featureStore = useCustomerFeaturesStore();
const userStore = useUserStore();
const appConn = ref<PromptsuiteAppConnectResponse>();
const route = useRoute();
const toast = useToast();

let relaySocket: Socket | undefined = undefined;

const userInput = ref('');
const userInputFocus = ref(false);
const temperature = ref(0.2);
const additionalUserInstructions = ref('');
const completionStreamContent = ref('');
const completionStreamInProgress = ref(false);
const completionContent = ref('');
const generatorLocale = ref(currentLanguage);

const processingProgressDisplay = ref(0);
const processingStatus = ref({
  isProcessing: false,
  message: '',
  statusColor: 'blue',
  progress: 0,
});

const toggleAdvancedOptions = ref(false);

let appTranslator = createTranslator(currentLanguage, [
  createTranslatorLanguage('de_CH', ['de_DE'], {}),
  createTranslatorLanguage('gsw_CH', ['de_CH'], {}),
  createTranslatorLanguage('de_DE', ['en_US'], {}),
  createTranslatorLanguage('en_US', [], {}),
]);

/* --------------------------------- METHODS -------------------------------- */

function handleConversationRelayMessage(message: string) {
  // try to decode json (assume it's an object)
  try {
    let relayMessage = JSON.parse(message);

    switch (relayMessage.type) {
      case 'completion_stream':
        handleConversationRelayMessageCompletionStream(relayMessage);
        break;
      case 'warning':
        toast.warning(relayMessage.data.message);
        break;
      case 'plugin.job_crawler.begin':
        processingStatus.value = {
          isProcessing: true,
          statusColor: 'secondary',
          message: 'Starting job crawler',
          progress: 0,
        };
        // toast.info('Job crawler started', { position: POSITION.BOTTOM_RIGHT });
        break;
      case 'plugin.job_crawler.progress':
        processingStatus.value = {
          isProcessing: true,
          statusColor: 'blue',
          message: 'Crawling ' + relayMessage.data.task_done_count + ' of ' + relayMessage.data.task_count,
          progress: relayMessage.data.task_done_count / relayMessage.data.task_count,
        };
        // toast.info(
        //   'Job crawler progress: ' + relayMessage.data.task_done_count + ' of ' + relayMessage.data.task_count,
        //   {
        //     position: POSITION.BOTTOM_RIGHT,
        //   }
        // );
        break;
      case 'plugin.job_crawler.success':
        setTimeout(() => {
          processingStatus.value = {
            isProcessing: true,
            statusColor: 'green',
            message: 'Finalizing',
            progress: 0.99,
          };

          setTimeout(() => {
            processingStatus.value = {
              isProcessing: false,
              message: 'Done',
              statusColor: 'green',
              progress: 1,
            };
          }, 600);
        }, 600);

        // toast.success('Job crawler finished', { position: POSITION.BOTTOM_RIGHT });
        break;
    }
  } catch (e) {
    console.error('Could not parse message', e);
  }
}

function handleConversationRelayMessageCompletionStream(message: PRMCompletionStream): void {
  completionStreamContent.value += message.data.chunk;
}

function connectToConversationRelay() {
  if (!appConn.value?.relay_url) {
    console.error('No relay host available to connect to, cannot connect to relay');
    return;
  }

  if (!appConn.value?.relay_token) {
    console.error('No token available to connect to, cannot connect to relay');
    return;
  }

  // disconnect from the relay if we are already connected
  if (relaySocket) {
    relaySocket.disconnect();
  }

  relaySocket = io(appConn.value.relay_url, {
    transports: ['websocket'],
    path: '/stream',
  });

  relaySocket.on('connect', () => {
    relaySocket?.emit('join', appConn.value?.relay_token);
  });

  relaySocket.on('message', handleConversationRelayMessage);
}

function getFullPromptContent(): string {
  let content = userInput.value;

  if (additionalUserInstructions.value.length > 0) {
    content += '\n\nReally important! Consider the following: ' + additionalUserInstructions.value;
  }

  return content;
}

function getPromptSuiteAppUserInput() {
  return {
    content: getFullPromptContent(),
    locale: generatorLocale.value,
    user_firstname: userStore.currentUser?.firstname ?? '',
  };
}

function performCompletion(closeAnimation: boolean = true) {
  if (!appConn.value?.data.id) {
    console.error('No app id available, cannot perform completion');
    return;
  }

  if (!appConn.value.relay_token) {
    console.error('No relay token available, cannot perform completion');
    return;
  }

  completionStreamInProgress.value = true;
  completionStreamContent.value = '';
  if (closeAnimation) {
    completionContent.value = '';
  }

  interactWithPromptSuiteApp(appConn.value.data.id, appConn.value.relay_token, getPromptSuiteAppUserInput())
    .then((response) => {
      // get the last message from the conversation
      let lastMessage = response.conversation[response.conversation.length - 1];

      // set the completion content
      completionContent.value = lastMessage.message;
    })
    .finally(() => {
      completionStreamInProgress.value = false;
    });
}

function copyOutputToClipboard() {
  navigator.clipboard.writeText(completionContent.value);
  toast.success('Copied to clipboard');
}

function regenerateCompletion() {
  performCompletion(false);
}

/* --------------------------------- COMPUTED -------------------------------- */

const generateButtonDisabled = computed(() => {
  return userInput.value.length === 0;
});

const editorExpanded = computed(() => {
  return !(completionStreamInProgress.value || completionContent.value.length > 0) || userInputFocus.value;
});

const userInputDebug = computed(() => {
  return JSON.stringify(getPromptSuiteAppUserInput(), null, 2);
});

/* --------------------------------- LIFECYCLE -------------------------------- */

onMounted(() => {
  fetchPromptSuiteAppConn(parseInt(route.params.id as string)).then((response) => {
    appConn.value = response;

    // rebuild the translator with the new data
    appTranslator = createTranslator(currentLanguage, [
      createTranslatorLanguage('de_CH', ['de_DE'], response.data.translations.de_CH ?? {}),
      createTranslatorLanguage('gsw_CH', ['de_CH'], response.data.translations.gsw_CH ?? {}),
      createTranslatorLanguage('de_DE', ['en_US'], response.data.translations.de_DE ?? {}),
      createTranslatorLanguage('en_US', [], response.data.translations.en_US ?? {}),
    ]);

    connectToConversationRelay();
  });
});

onUnmounted(() => {
  relaySocket?.disconnect();
});

// continuously update the progress display
function updateProgressDisplay() {
  if (processingStatus.value.progress === 1 || 0) {
    processingProgressDisplay.value = processingStatus.value.progress;
  } else {
    processingProgressDisplay.value += 0.01;
    processingProgressDisplay.value = Math.min(processingProgressDisplay.value, processingStatus.value.progress);
  }

  requestAnimationFrame(updateProgressDisplay);
}

requestAnimationFrame(updateProgressDisplay);
</script>

<style lang="scss" scoped>
.promptapp-container {
  height: 100%;
  position: relative;

  .promptapp-sidebar {
    width: 32%;
    transition: width 0.5s ease-in-out;

    height: calc(100vh - 92px);
    float: left;
    background-color: #fff;
    border-right: 1px solid var(--tblr-border-color);
    overflow-y: scroll;

    &.expanded {
      // width: 49%;
    }

    > div {
      margin: var(--lead-container-padding);
    }
  }

  .promptapp-main {
    width: 65%;
    float: left;

    &.collapsed {
      // width: 49%;
    }
  }
}

.slide-fade-down-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-down-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-down-enter-from,
.slide-fade-down-leave-to {
  transform: translateY(-100px) rotate3d(1, 0, 0, 90deg);
  opacity: 0;
}

// fix the default state
.slide-fade-down-enter {
  transform: translateY(0) rotate3d(1, 0, 0, 0deg);
  opacity: 1;
}
</style>
