<template>
  <div class="d-flex align-items-center">
    <div class="row flex-fill">
      <OrderDetails :order="props.order"> </OrderDetails>
      <OrderPills :order="props.order" class="ps-5 pe-4" />
    </div>
    <div class="d-none d-xl-block vr" />
    <OrderSocialStats class="d-none d-xl-flex px-3 px-xxl-4 flex-wrap flex-xxl-nowrap" :order="props.order" />
    <div class="d-none d-lg-block vr" />
    <OrderExpirySlider class="d-none d-xxl-block col-2 px-3" :order="props.order" />
    <OrderBanner class="d-none d-lg-flex ps-3" :order="props.order" />
  </div>
</template>
<script setup lang="ts">
import OrderBanner from './order_elements/OrderBanner.vue';
import OrderExpirySlider from './order_elements/OrderExpirySlider.vue';
import OrderSocialStats from './order_elements/OrderSocialStats.vue';
import OrderDetails from './order_elements/OrderDetails.vue';
import type { Order } from '@/types/types';
import OrderPills from './order_elements/OrderPills.vue';

const props = defineProps<{ order: Order }>();
</script>
