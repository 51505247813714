import type { ImageObject } from '@/types/types';

export const createFile = (file: ImageObject, status: StatusType = 'loading'): UploadableFile => ({
  file,
  blob: file.blob,
  status,
  id: createId(file.description, file.size),
  percentage: typeof status == 'boolean' ? 100 : 15,
  // On initial upload it will generate objectURL, and on an uploaded image it will get the url_preview
  // The second case happens when you got back and forth between steps
  url: file.blob ? URL.createObjectURL(file.blob as Blob) : file.proxy ? file.proxy.url_preview : undefined,
});

export const createId = (desc: string | null, size: number) => `${desc || ''}-${size || '0'}`;

export function startProgress(file: UploadableFile) {
  if (file.status === true) {
    file.percentage = 100;
    return;
  }
  // We don't go above 80 unless it's uploaded
  const increaseProgress = () => {
    if (file.percentage >= 80) return clearInterval(file.interval);
    file.percentage += 2;
  };

  // Start incrementing from {initial} up to 80 every 70 miliseconds faking a upload progress for now
  file.interval = setInterval(() => increaseProgress(), 35);
}

export type StatusType = 'loading' | true | false;

export interface UploadableFile {
  file: ImageObject;
  blob?: File;
  id: string | number;
  url?: string;
  status: StatusType;
  percentage: number;
  interval?: number;
}
