<template>
  <div>
    <HeaderNavbar>
      <HeaderNavbarSearch v-model="searchQuery"></HeaderNavbarSearch>
    </HeaderNavbar>
    <div class="container-lead">
      <li-page-header
        :title="$t('settings.accounts.title')"
        :subtitle="$t('settings.accounts.subtitle')"
        :has-filters="false"
      >
        <RouterLink :to="{ name: 'settings.accounts.detail', params: { id: '+' } }" class="btn btn-primary"
          >{{ $t('account.create.button') }} <IconUserPlus :size="16" class="ms-2"></IconUserPlus
        ></RouterLink>
      </li-page-header>

      <RemoteListView endpoint="/v1/account/accounts" :search-query="searchQuery" class="mt-4">
        <template #loading>
          <div class="row">
            <div class="col-md-4" v-for="i in new Array(6)" :key="i">
              <div class="card mb-3">
                <div class="card-body p-4">
                  <h3 class="card-title mb-1">
                    <div class="placeholder col-7" />
                  </h3>
                  <div>
                    <div class="placeholder col-3" />
                    <br />
                    <div class="placeholder col-4" />
                    <br />
                    <div class="placeholder col-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #collection="{ collection: accounts }">
          <div class="row">
            <div class="col-md-4" v-for="(account, index) in accounts" :key="account.id">
              <div
                class="animate__animated animate__fadeInUp animate__faster card selectable-card mb-3"
                :style="{ animationDelay: `${index * 0.1}s` }"
              >
                <RouterLink
                  :to="{ name: 'settings.accounts.detail', params: { id: account.id } }"
                  custom
                  v-slot="{ navigate }"
                >
                  <div class="card-body p-4" @click="navigate">
                    <small class="float-end text-primary" v-if="account.is_admin">ADMIN</small>
                    <div class="d-flex">
                      <div>
                        <span class="avatar me-3 rounded">
                          <span class="badge bg-success"></span>{{ getAcronym(account.email) }}
                        </span>
                      </div>
                      <div>
                        <h2 class="fw-bold text-truncate mb-2">
                          {{ account.firstname ?? account.email.substring(0, account.email.indexOf('@')) }}
                          <span class="fw-normal">{{ account.lastname }}</span>
                        </h2>
                        <p class="text-muted mb-0">{{ account.email }}</p>
                      </div>
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </template>
        <template #footer="{ page, changePage, startRecordIndex, endRecordIndex, filterCount, pageCount }">
          <FixedFooterSection>
            <p class="m-0">
              <strong>{{ $t('orders.pagination.page') }} {{ page }} {{ $t('common.of') }} {{ pageCount }}</strong>
              <span class="text-muted">
                ({{ startRecordIndex }}-{{ endRecordIndex }} {{ $t('common.of') }} {{ filterCount }}
                {{ $t('settings.accounts.title') }})
              </span>
            </p>
            <div class="d-flex gap-3">
              <button class="btn" :disabled="page <= 1" @click="changePage(page - 1)">
                {{ $t('orders.pagination.prev') }}
              </button>
              <button class="btn" :disabled="page >= pageCount" @click="changePage(page + 1)">
                {{ $t('orders.pagination.next') }}
              </button>
            </div>
          </FixedFooterSection>
        </template>
      </RemoteListView>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PageHeader as LiPageHeader } from '@prospective/lithium';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';
import RemoteListView from '@/components/RemoteListView.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import { getAcronym } from '@/func/string/acronym';
import { IconUserPlus } from '@tabler/icons-vue';

/* --------------------------------- STATE -------------------------------- */

const featureStore = useCustomerFeaturesStore();

const searchQuery = ref('');
</script>

<style lang="scss" scoped></style>
