<template>
  <header class="sticky-top">
    <div>
      <div class="navbar navbar-light navbar-lead-head">
        <div class="container-lead d-flex justify-content-start">
          <slot></slot>

          <div :class="{ 'ms-auto': props.msAuto }" v-if="props.showProfile && userStore.currentUser">
            <div class="d-flex" :class="{ 'header-segment': !props.msAuto }">
              <Avatar :name="userStore.currentUser.email ?? '?'" :size="32" />
              <div class="ms-2 d-none d-md-block">
                <div>
                  <strong>{{ userStore.currentUser.customer?.data?.name }}</strong>
                </div>
                <div class="text-muted">
                  <small>{{ userStore.currentUser.email }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { Avatar } from '@prospective/lithium';
import { useUserStore } from '@/stores/user';

// Props
export interface Props {
  showProfile?: boolean;
  msAuto?: boolean;
}

const userStore = useUserStore();

// declare the defaults
const props = withDefaults(defineProps<Props>(), {
  showProfile: true,
  msAuto: true,
});
</script>

<style></style>
