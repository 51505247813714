<template>
  <div>
    <AutosizeTextarea
      :rows="4"
      v-model="proxyValue"
      :label="appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_label')"
      :placeholder="appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_placeholder')"
      class="mb-1"
      @focus="emit('focus')"
      @blur="emit('blur')"
    ></AutosizeTextarea>
    <p>
      <small class="text-muted">{{ appTranslator.get('promptapp:' + appConn?.data.id + '.user_prompt_hint') }}</small>
    </p>
  </div>
</template>

<script setup lang="ts">
import type { PromptsuiteAppConnectResponse } from '@/api/promptsuite';
import AutosizeTextarea from '@/components/form/AutosizeTextarea.vue';
import type { Translator } from '@prospective/lithium';
import { computed } from 'vue';

const props = defineProps<{
  appTranslator: Translator;
  appConn: PromptsuiteAppConnectResponse;
  modelValue: string;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'focus'): void;
  (event: 'blur'): void;
}>();

const proxyValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
</script>
