<template>
  <div class="card poolmirror-campaign-card mb-2">
    <!-- <div v-if="job.priority" class="ribbon bg-green">Promoted</div> -->

    <div class="p-3">
      <div class="row">
        <div class="col-1" style="width: 74px">
          <span class="avatar avatar-md">
            <img v-if="logoUrl" :src="logoUrl" style="transform: scale(0.8)" />
          </span>
        </div>
        <div class="col">
          <div class="float-end">
            <!-- <a class="btn btn-outline-secondary" :href="job.link" target="_blank"
              >Stelleninserat öffnen <i class="ti ti-link"></i
            ></a> -->
            <LoadingButton
              v-if="!job.priority"
              class="btn btn-outline-success"
              :class="{ disabled: !canActivate }"
              :disabled="!canActivate"
              @press="activateJob"
            >
              JobIQ Aktivieren <i class="ti ti-cloud"></i>
            </LoadingButton>
            <LoadingButton
              v-if="job.priority && canBeDeactivated"
              class="btn btn-outline-warning"
              @press="deactivateJob"
            >
              JobIQ Deaktivieren <i class="ti ti-cloud-off"></i>
            </LoadingButton>
          </div>
          <div>
            <div class="card-company-name nobr">
              {{ job.company_name }}
            </div>
            <h3 class="card-title nobr">
              <a :href="job.link" target="_blank">{{ job.title }}</a>
            </h3>
            <span class="text-muted">#{{ job.reference_num }} • {{ job.provider_key }}</span>
            <div class="mt-3">
              <div class="crawled-job-info-pill">
                <span class="badge bg-blue-lt nobr" :style="{ maxWidth: '200px' }">
                  <IconLocation :size="14" class="me-1"></IconLocation> {{ job.workplace_location }}
                </span>
                <span class="badge bg-blue-lt nobr ms-2" :style="{ maxWidth: '200px' }">
                  <IconPercentage :size="14" class="me-1"></IconPercentage>
                  {{ job.properties.ohws_attr_stellenprozent }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { LoadingButton } from '@prospective/lithium';

import { setPoolmirrorCrawledJobPriority } from '@/api/poolmirror';
import type { ResponseData } from '@/api/client';

import { IconLocation, IconPercentage } from '@tabler/icons-vue';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import { canDeactivateJob } from '@/func/poolmirror/state';

// const tmpTodoLogoUrl =
//   'https://img.pjas.ch/R5mhHZ6Ze-lSL9birUMld5YcGHSBj8b1ssEPKqrWmCA/sm:1/czM6Ly9wamFzL2ltYWdlcy9iNjIxMTY0MmRkMzI2ZGM2Mjk5NmJlZDUxNDlkMTI5Yi5wbmc.png';

const featureStore = useCustomerFeaturesStore();

const props = defineProps<{
  canActivate: boolean;
  campaignId: number;
  job: ResponseData;
  publication?: ResponseData;
}>();

const emits = defineEmits<{
  (event: 'did-change-priority'): void;
}>();

const canBeDeactivated = computed(() => {
  if (!props.job) {
    return false;
  }

  return canDeactivateJob(props.job, props.publication, featureStore.featurePoolMirrorCampaign);
});

const logoUrl = computed(() => {
  return featureStore.featurePoolMirrorCampaign?.logo_url;
});

function activateJob() {
  setPoolmirrorCrawledJobPriority(props.campaignId, props.job.id, 1).then(() => {
    emits('did-change-priority');
  });
}

function deactivateJob() {
  setPoolmirrorCrawledJobPriority(props.campaignId, props.job.id, 0).then(() => {
    emits('did-change-priority');
  });
}
</script>

<style>
.preview-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.preview-image-overlay-label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-weight: bold;
}

.poolmirror-campaign-card .card-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;
}
.poolmirror-campaign-card .card-company-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #777;
}

p.nobr,
span.nobr,
.poolmirror-campaign-card .card-title.nobr,
.poolmirror-campaign-card .card-company-name.nobr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
