<template>
  <component :is="elementType">
    <slot name="header"></slot>
    <slot v-if="isLoading" name="loading"></slot>
    <template v-else-if="collection && collection.length > 0">
      <slot name="collection" :collection="collection" :isLoading="isLoading">
        <template v-for="(item, index) in collection">
          <slot name="element" :item="item" :index="index"></slot>
        </template>
      </slot>
    </template>
    <template v-else>
      <slot name="empty">
        <div class="empty">
          <div class="empty-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="9" y1="10" x2="9.01" y2="10" />
              <line x1="15" y1="10" x2="15.01" y2="10" />
              <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
            </svg>
          </div>
          <p class="empty-title">{{ emptyPageTitle }}</p>
          <p class="empty-subtitle text-muted">{{ emptyPageSubtitle }}</p>
        </div>
      </slot>
    </template>

    <slot
      v-if="showFooter"
      name="footer"
      :page="page"
      :totalCount="totalCount"
      :startRecordIndex="startRecordIndex"
      :endRecordIndex="endRecordIndex"
      :filterCount="filterCount"
      :pageCount="pageCount"
      :changePage="changePage"
    >
      <div :class="footerClass">
        <p class="m-0 text-muted">
          <small>
            Showing {{ startRecordIndex }} - {{ endRecordIndex }} of <strong>{{ filterCount }}</strong> entries
            (filtered from <strong>{{ totalCount }}</strong> total entries)
          </small>
        </p>
        <li-pagination v-model="page" :page-count="pageCount" class="m-0 ms-auto" @change="refresh" />
      </div>
    </slot>
  </component>
</template>

<script setup lang="ts">
defineProps({
  elementType: {
    type: String,
    default: 'div',
  },
  footerClass: {
    type: String,
    default: 'remote-list-view-footer d-flex align-items-center',
  },
  endpoint: {
    type: String,
    required: true,
  },
  searchQuery: {
    type: String,
    default: '',
  },
  resolve: {
    type: String,
    default: '',
  },
  order: {
    type: String,
    default: 'id',
  },
  orderDir: {
    type: String,
    default: 'asc',
  },
  emptyPageTitle: {
    type: String,
    default: 'No results found',
  },
  emptyPageSubtitle: {
    type: String,
    default: "Try adjusting your search or filter to find what you're looking for.",
  },
  pageSize: {
    type: Number,
    default: 20,
  },
  parameters: {
    type: Object,
    default() {
      return {};
    },
  },
  resetPage: {
    type: Boolean,
    default: false,
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
});
</script>

<script lang="ts">
import { Pagination as LiPagination } from '@prospective/lithium';
import { request } from '@/api/client';
import { debounce } from '@prospective/lithium';
import { defineComponent } from 'vue';

export interface Options {
  noLoadingState?: boolean;
}

export default defineComponent({
  components: {
    LiPagination,
  },
  emits: ['before-fetch', 'after-fetch', 'page-resetted', 'did-fetch'],

  data() {
    return {
      isLoading: true,
      page: 1,
      collection: [] as { [key: string]: any }[],
      totalCount: 0,
      filterCount: 0,
    };
  },

  computed: {
    pageCount() {
      return Math.floor(this.filterCount / this.pageSize) + 1;
    },
    startRecordIndex() {
      return (this.page - 1) * this.pageSize + 1;
    },
    endRecordIndex() {
      return (this.page - 1) * this.pageSize + this.pageSize;
    },
  },

  watch: {
    searchQuery() {
      this.refresh();
    },
    order() {
      this.refresh();
    },
    orderDir() {
      this.refresh();
    },
    parameters: {
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },

  mounted() {
    this.fetch();
    this.fetchDebouncer = debounce<typeof this.fetch>(this.fetch, 300);
  },

  created() {
    window.addEventListener('keyup', this.handleKeypress);
  },
  unmounted() {
    window.removeEventListener('keyup', this.handleKeypress);
  },

  methods: {
    fetchDebouncer: null as any,

    refresh() {
      this.isLoading = true;
      this.page = Math.max(1, this.page);
      this.fetchDebouncer();
    },

    fetch(options: Options = {}) {
      if (!this.endpoint) {
        console.error('Cannot fetch "RemoteListView" without a valid endpoint.');
        return;
      }

      if ((options.noLoadingState ?? false) === false) {
        this.isLoading = true;
      }

      // there has to be a better way...
      let requestParameters = JSON.parse(JSON.stringify(this.parameters));

      // set some required request params
      requestParameters.count = 1;

      // reset the page on filtering and searching
      if (this.$props.resetPage) {
        this.page = 1;
      }
      requestParameters.page = this.page - 1;
      requestParameters.page_size = this.pageSize;
      requestParameters.order = this.order ?? 'id';
      requestParameters.order_dir = this.orderDir;
      requestParameters.search = this.searchQuery;
      if (this.resolve) requestParameters.resolve = this.resolve;

      this.$emit('before-fetch', this);

      // run the request
      request('GET', this.endpoint, requestParameters)
        .then((response) => {
          this.collection = response.data;
          this.totalCount = response?.total_count ?? null;
          this.filterCount = response?.filter_count ?? null;
          this.isLoading = false;
          this.$emit('after-fetch', this);
          this.$emit('page-resetted', this);
          this.$emit('did-fetch', response.data);
        })
        .catch((e) => console.error(e));
    },

    /**
     * Returns an items unique key
     */
    getItemKey(item: any) {
      return item?.id;
    },

    handleKeypress(event: KeyboardEvent) {
      if (event.key === 'ArrowRight') {
        this.page++;
        this.refresh();
      } else if (event.key === 'ArrowLeft') {
        this.page--;
        this.refresh();
      }
    },
    changePage(newPage: number) {
      this.page = newPage;
      this.refresh();
    },
  },
});
</script>

<style>
.no-data {
  padding: 20px;
  font-size: 16px;
}
</style>
