<template>
  <div v-if="featureStore.featureOrderManagment">
    <HeaderNavbar>
      <HeaderNavbarSearch v-model="searchQuery"> </HeaderNavbarSearch>
    </HeaderNavbar>

    <div class="container-lead">
      <LiPageHeader :title="$t('nav.orders')" :has-filters="false">
        <!-- Display layouts -->
        <ListLayout :layouts="listLayouts" v-model="listLayout" class="me-2" />
        <!-- Vertical separator -->
        <div class="vr mx-2" style="height: 10px" />
        <!-- Filters shown on >=768px -->
        <div class="d-flex flex-row gap-3">
          <SortButton v-model="sortState" :sort-options="sortOptions" />

          <FilterButton v-model="data.requestParameters.EQL" />

          <router-link
            to="/dashboard/orders/create"
            class="btn btn-icon btn-primary"
            v-tooltip="$t('orders.create_button')"
            v-if="featureStore.featureCanCreateEditOrder"
          >
            <i class="ti ti-m ti-plus" />
          </router-link>
        </div>
        <!-- End of filters -->
      </LiPageHeader>

      <div class="row row-cards mt-3">
        <div class="col-12">
          <RemoteListView
            endpoint="/v1/account/orders"
            :search-query="searchQuery"
            :order="sortState.key"
            :order-dir="sortState.direction"
            :page-size="10"
            :reset-page="data.resetPage"
            :parameters="data.requestParameters"
            @page-resetted="data.resetPage = false"
          >
            <template #empty>
              <div class="card card-body empty my-3">
                <div class="empty-icon">
                  <IconMoodEmpty :size="24" :stroke-width="1.2" />
                </div>
                <p class="empty-title">No Orders found</p>
                <p class="empty-subtitle text-muted">
                  Try adjusting your search or filters to find what you're looking for. Or create a new order.
                </p>
                <div class="empty-action">
                  <router-link to="/dashboard/orders/create" class="btn btn-primary"
                    >Create new Order <i class="ti ti-plus"></i
                  ></router-link>
                </div>
              </div>
            </template>
            <template #loading>
              <div class="card placeholder-glow">
                <div class="card-body">
                  <div v-for="i in new Array(4)" :key="i" class="d-flex align-items-center gap-4">
                    <div class="avatar placeholder" />
                    <div class="d-flex col-4 flex-column gap-2 me-auto">
                      <div class="placeholder col-8 mb-2" />
                      <div class="placeholder placeholder-xs col-4"></div>
                    </div>

                    <div class="placeholder col-3" />
                    <div class="placeholder col-3" />
                  </div>
                </div>
              </div>
            </template>
            <template #collection="{ collection }">
              <div :class="listLayout === 'list' ? 'card card-body collection-wrapper' : 'grid-wrapper'">
                <component
                  :is="listLayout === 'list' ? OrderListElement : OrderGridElement"
                  v-for="(item, index) in collection"
                  :key="item.id"
                  :order="(item as Order)"
                  class="animate__animated animate__fadeInUp animate__faster"
                  :class="listLayout === 'list' ? '' : 'card card-body'"
                  :style="{ animationDelay: `${index * 0.1}s` }"
                />
              </div>
            </template>
            <template #footer="{ page, changePage, startRecordIndex, endRecordIndex, filterCount, pageCount }">
              <FixedFooterSection>
                <p class="m-0">
                  <strong>{{ $t('orders.pagination.page') }} {{ page }} {{ $t('common.of') }} {{ pageCount }}</strong>
                  <span class="text-muted">
                    ({{ startRecordIndex }}-{{ endRecordIndex }} {{ $t('common.of') }} {{ filterCount }}
                    {{ $t('orders.list.title') }})
                  </span>
                </p>
                <div class="d-flex gap-3">
                  <button class="btn" :disabled="page <= 1" @click="changePage(page - 1)">
                    {{ $t('orders.pagination.prev') }}
                  </button>
                  <button class="btn" :disabled="page >= pageCount" @click="changePage(page + 1)">
                    {{ $t('orders.pagination.next') }}
                  </button>
                </div>
              </FixedFooterSection>
            </template>
          </RemoteListView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { PageHeader as LiPageHeader } from '@prospective/lithium';
import { IconMoodEmpty } from '@tabler/icons-vue';
import OrderListElement from '@/components/orders/OrderListElement.vue';
import OrderGridElement from '@/components/orders/OrderGridElement.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import RemoteListView from '../../components/RemoteListView.vue';
import HeaderNavbar from '../header/HeaderNavbar.vue';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';

import SortButton, { type SortState } from '@/components/generic/SortButton.vue';
import FilterButton from '@/components/orders/FilterButton.vue';

import translator from '@/locale/translator';
import ListLayout, { type ListLayoutsProps } from '@/components/generic/ListLayout.vue';
import { IconList, IconLayoutGrid } from '@tabler/icons-vue';
import type { Order } from '@/types/types';

const featureStore = useCustomerFeaturesStore();
const searchQuery = ref('');
const sortState = ref<SortState>({
  key: 'id',
  direction: 'desc',
});

const listLayouts = ref<ListLayoutsProps['layouts']>([
  { label: translator.get('orders.layout.list'), value: 'list', icon: IconList },
  { label: translator.get('orders.layout.grid'), value: 'grid', icon: IconLayoutGrid },
]);
const listLayout = ref<'list' | 'grid'>('list');

const sortOptions = computed(() => {
  let options = [
    { key: 'id', label: 'ID' },
    { key: 'created_at', label: translator.get('sort.field.created_at') },
    { key: 'title', label: translator.get('orders.list.sort.title') },
  ];

  if (searchQuery.value) {
    options.push({ key: 'idfs', label: translator.get('sort.field.relevance') });
  }

  return options;
});

watch(searchQuery, (value) => {
  sortState.value = value ? { key: 'idfs', direction: 'asc' } : { key: 'id', direction: 'desc' };
});

const data = reactive({
  sortKey: 'id',
  sortDir: 'desc',
  searchQuery: '',
  resetPage: false,
  requestParameters: {
    EQL: '',
    resolve: 'company,company.logo_image,stats_cache,creator',
  },
});
</script>
<style scoped>
.collection-wrapper {
  /* remove margin later, left for pagination space */
  margin-bottom: 1em;
}
.collection-wrapper > div:not(:first-of-type),
.placeholder-glow .card-body > div:not(:first-of-type) {
  border-top: 1px solid var(--tblr-border-color);
}
.collection-wrapper > div,
.placeholder-glow .card-body > div {
  padding: 1rem 0;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: auto;
  gap: 16px;
}
</style>
