<template>
  <div class="d-flex justify-content-between">
    <div :class="{ 'data-pills': true, 'no-price': !displayBudget }">
      <StatusIndicator :status="props.order?.lead_order_status" />
      <StatsPill :text="budgetText" :icon="IconCoins" v-if="displayBudget" />
      <StatsPill :text="workloadText" :icon="IconPercentage" />
      <StatsPill :text="workplace_location || $t('orders.list.worldwide')" :icon="IconMapPin" />
    </div>
    <div class="avatar-pill d-none d-sm-block" v-if="creatorName">
      <StatsPill :text="creatorName" :icon="IconUserCheck" v-tooltip="{ content: creatorNameTooltip, html: true }" />
    </div>
  </div>
</template>
<script setup lang="ts">
import StatsPill from '@/components/generic/StatsPill.vue';
import StatusIndicator from '@/components/generic/StatusIndicator.vue';
import { IconCoins, IconPercentage, IconMapPin, IconUserCheck } from '@tabler/icons-vue';
import type { Order } from '@/types/types';
import { computed } from 'vue';
import { getCurrencySign } from '@/locale/format';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import { getAcronym } from '@/func/string/acronym';

const props = defineProps<{ order: Order }>();
const { workplace_location, workload_min, workload_max, budget_gross_total, budget_currency } = props.order || {};
const featureStore = useCustomerFeaturesStore();
const displayBudget = computed(() => featureStore.featureOrderManagment?.display_budget);

const workloadText = computed(() => {
  if (workload_min === 100) return '100%';
  return `${workload_min || 0}% - ${workload_max || 100}%`;
});
const budgetText = computed(() => {
  const sign = getCurrencySign(budget_currency || '');
  const budget = budget_gross_total ? budget_gross_total / 100 : 0;
  return `${sign} ${budget}`;
});
const creatorName = computed(() => {
  const creator = props.order?.creator?.name || '';
  return creator ? getAcronym(creator).toUpperCase() : '';
});
const creatorNameTooltip = computed(() => {
  return 'Created by<br><strong>' + (props.order?.creator?.name || '') + '</strong>';
});
</script>
<style scoped>
.data-pills {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: min-content min-content min-content auto;
  max-width: max-content;
}
.no-price {
  grid-template-columns: min-content min-content auto;
}
</style>
