<template>
  <DropDown :align="'right'" width="300px">
    <template #trigger="{ toggleDropdown }">
      <a class="btn btn-white btn-icon h-100 d-flex align-items-center" @click.prevent="toggleDropdown">
        <IconFilter :size="22" />
      </a>
    </template>
    <template #dropdown>
      <div class="p-3">
        <div>
          <label class="form-label">{{ $t('orders.filters.company_name') }}</label>
          <v-select
            class="mb-3"
            :placeholder="$t('orders.filters.company_name')"
            :modelValue="internalState.company_name"
            :options="companies"
            @update:modelValue="(val) => changeInternal('company_name', val)"
          />
        </div>
        <div>
          <label class="form-label">{{ $t('orders.filters.location') }}</label>
          <v-select
            class="mb-3"
            :placeholder="$t('orders.filters.location')"
            :modelValue="internalState.workplace_location"
            :options="countries"
            @update:modelValue="(val) => changeInternal('workplace_location', val)"
          />
        </div>
        <div>
          <label class="form-label">{{ $t('orders.filters.language') }}</label>
          <v-select
            multiple
            :closeOnSelect="false"
            :searchable="false"
            class="mb-3"
            :modelValue="internalState.language"
            :options="[
              { value: 'de', label: 'German' },
              { value: 'en', label: 'English' },
              { value: 'fr', label: 'French' },
              { value: 'it', label: 'Italian' },
            ]"
            :reduce="(o) => o.value"
            @update:modelValue="(val) => changeInternal('language', val)"
            @click.stop
          />
        </div>
        <div>
          <label class="form-label">{{ $t('orders.filters.creator') }}</label>
          <v-select
            :filterable="true"
            class="mb-3"
            :modelValue="internalState.creator_string"
            :options="creators"
            :reduce="(o) => o.value"
            @update:modelValue="(val) => changeInternal('creator_string', val)"
            @click.stop
          />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="w-50 d-flex justify-content-center">
            <button class="btn btn-link p-0 text-danger" @click="resetFilters">
              {{ $t('orders.filters.reset_filters') }}
            </button>
          </div>

          <button class="btn btn-dark w-50" @click="calculateFilters">{{ $t('orders.filters.apply') }}</button>
        </div>
      </div>
    </template>
  </DropDown>
</template>

<script setup lang="ts">
import { fetchAutocompleteFields } from '@/api/order';
import { fetchCustomerAccounts } from '@/api/customer';
import { DropDown } from '@prospective/lithium';
import { IconFilter } from '@tabler/icons-vue';
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { createFilterEQL } from '@/filters/listFilters';

const defaultFilters = { company_name: '', workplace_location: '', language: [], creator_string: '' };

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const companies = ref<string[]>(['']);
const countries = ref<string[]>(['']);
const creators = ref<string[]>(['']);
const internalState = ref(defaultFilters);

onMounted(() => {
  fetchAutocompleteFields('company_name', { q: '' })
    .then((c) => (companies.value = c))
    .catch(() => useToast().error('Failed to catch available companies'));
  fetchAutocompleteFields('workplace_location', { q: '' })
    .then((c) => (countries.value = c))
    .catch(() => useToast().error('Failed to catch available countries'));
  fetchCustomerAccounts()
    .then((res) => {
      creators.value = res.data.map((user) => ({
        value: user.id,
        label: user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : user.email,
      }));
    })
    .catch(() => useToast().error('Failed to catch available creators'));
});

/* --------------------------------- METHODS -------------------------------- */
const changeInternal = (key: keyof typeof internalState.value, value: any) => {
  internalState.value = { ...internalState.value, [key]: value };
};
const resetFilters = () => (internalState.value = defaultFilters);
const calculateFilters = () => emit('update:modelValue', createFilterEQL(internalState.value));
</script>
