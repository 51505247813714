import logoLead from '@/assets/logo/lighthouse1_free.png';
import logoLeadText from '@/assets/logo/lighthouse1_free_text.png';
import logoHRIQ from '@/assets/logo/logo_reciq_full.svg';

export const appType = import.meta.env.VITE_APP_TYPE;

/**
 * Simple configuration file for the app
 */
export const appName = (() => {
  switch (appType) {
    case 'HRIQ':
      return 'Recruiting IQ';
    default:
      return 'LEAD';
  }
})();

export const appNameShort = (() => {
  switch (appType) {
    case 'HRIQ':
      return 'HRIQ';
    default:
      return 'LEAD';
  }
})();

/**
 * Locale and currency
 */
export const defaultLocale = 'de-CH';
export const defaultCurrency = 'CHF';

/**
 * Campaign running time
 */
export const defaultMinimumCampaignDuration = 20; // in days
export const defaultCampaignDuration = 30; // in days

/**
 * Budgeting
 */
export const defaultDailyBudget = 50 * 100; // 50 CHF
export const defaultMinimumDailyBudget = 40 * 100; // 40 CHF

/**
 * Logo Path
 */
export const defaultLogoPath = (() => {
  if (appType === 'HRIQ') {
    return logoHRIQ;
  }

  return logoLead;
})();

export const defaultLogoTextPath = (() => {
  if (appType === 'HRIQ') {
    return logoHRIQ;
  }

  return logoLeadText;
})();
