import translator from '@/locale/translator.js';

import { IconDashboard } from '@tabler/icons-vue';
import type { Component } from 'vue';

/**
 * PJAS Lead main navigation
 */
export interface MainNavElement {
  label: string;
  icon?: Component;
  to: { name?: string; path?: string; exact?: boolean };
  teleportId?: string;
  active: boolean;
}

export function getMainNavigationElements(): MainNavElement[] {
  return [
    {
      label: translator.get('nav.home'),
      icon: IconDashboard,
      to: { name: 'home', exact: true },
      active: false,
    },
  ];
}
