<template>
  <div>
    <div class="form-selectgroup form-selectgroup-boxes d-flex flex-column">
      <label v-for="provider in providers" :key="provider.internal" class="form-selectgroup-item flex-fill">
        <input
          v-model="internalPlattforms"
          type="checkbox"
          class="form-selectgroup-input"
          :true-value="[]"
          :value="provider.internal"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div class="form-selectgroup-label-content d-flex align-items-center">
            <!-- <span class="avatar me-3">JL</span> -->
            <span
              class="avatar me-3"
              :style="{
                backgroundImage: 'url(https://jobiq.pjas.ch' + provider.logo + ')',
                backgroundSize: 'contain',
                backgroundColor: 'transparent',
                width: '80px',
              }"
            ></span>
            <div>
              <div class="font-weight-medium">{{ provider.name }}</div>
              <div class="text-muted">{{ provider.subtitle }}</div>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue?: string[];
}>();

interface Provider {
  name: string;
  subtitle: string;
  logo: string;
  internal: string;
}

const providers: Provider[] = [
  {
    name: 'Adform',
    subtitle: 'Display Banner Ads',
    logo: '/assets/images/ad_provider/adform.png',
    internal: 'adform',
  },
  {
    name: 'Meta',
    subtitle: 'Facebook & Instagram Ads',
    logo: '/assets/images/ad_provider/Facebook-Logo.png',
    internal: 'facebook',
  },
  // {
  //   name: 'Googleads',
  //   subtitle: 'Display Banner Ads',
  //   logo: '/assets/images/ad_provider/googleads.png',
  //   internal: 'googleads',
  // },
  // {
  //   name: 'Indeed',
  //   subtitle: 'Display Banner Ads',
  //   logo: '/assets/images/ad_provider/indeed.png',
  //   internal: 'indeed',
  // },
  {
    name: 'Google Search',
    subtitle: 'Google Search Ads',
    logo: '/assets/images/ad_provider/googleads.png',
    internal: 'persomatch',
  },
  // {
  //   name: 'Hicandidate',
  //   subtitle: 'Display Banner Ads',
  //   logo: '/assets/images/ad_provider/hicandidate.svg',
  //   internal: 'hicandidate',
  // },
];

const internalPlattforms = computed<string[]>({
  get() {
    return props.modelValue ?? providers.map((provider) => provider.internal);
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
