<template>
  <div class="chat-item">
    <div class="row align-items-end" :class="{ 'justify-content-end': isMe }">
      <div class="col-auto" v-if="!isMe">
        <UserAvatar :name="message.actor.name"></UserAvatar>
      </div>
      <div class="col col-lg-6">
        <div class="chat-bubble" :class="{ 'chat-bubble-me': isMe }">
          <!-- <span class="badge bg-blue is-unread-marker" v-if="message.unread"></span> -->
          <div class="chat-bubble-title">
            <div class="row">
              <div class="col chat-bubble-author">{{ message.actor.name }}</div>
              <div class="col-auto chat-bubble-date">{{ createdAtString }}</div>
            </div>
          </div>
          <div class="chat-bubble-body" v-html="htmlMessage"></div>
          <div class="chat-attachments" v-if="message.attachments">
            <ChatAttachment v-for="att in message.attachments.data" :attachment="att" :key="att.filename" />
          </div>
        </div>
      </div>
      <div class="col-auto" v-if="isMe">
        <UserAvatar :name="message.actor.name"></UserAvatar>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ChatAttachment from './ChatAttachment.vue';
import type { IChatMessage } from '@/types/chat.js';
import { computed, ref, onMounted, onUnmounted } from 'vue';

import { formatDistanceToNowStrict } from 'date-fns';
import { marked } from 'marked';
import UserAvatar from '@/components/user/UserAvatar.vue';

/* ---------------------------------- PROPS --------------------------------- */

const props = withDefaults(
  defineProps<{
    message: IChatMessage;
  }>(),
  {}
);

/* ---------------------------------- STATE --------------------------------- */
let interval: number;
const timerCounter = ref(1);

/* -------------------------------- COMPUTED -------------------------------- */

const isMe = computed(() => {
  return props.message.is_me ?? false;
});

const createdAtString = computed(() => {
  const fakeConst = timerCounter.value; // will cause computed to recalculate
  return formatDistanceToNowStrict(new Date(props.message.created_at), { addSuffix: true });
});

const htmlMessage = computed(() => {
  return marked.parse(props.message.message, {
    breaks: true,
  });
});

/* ------------------------------- LIFECYCLE ------------------------------ */
onMounted(() => {
  // This counter will update the created_at computed property every 3 seconds
  interval = setInterval(() => timerCounter.value++, 3_000);
});
onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style lang="scss" scoped>
.is-unread-marker {
  position: absolute;
  left: -5px;
  top: 23px;
}
.chat-attachments {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  max-height: 100px;
  margin-top: 4px;
}
</style>
