<template>
  <div class="container container-xl row justify-content-center align-items-stretch">
    <div class="col-xl">
      <div class="card card-md h-full">
        <div class="card-header">
          <h1 class="">{{ $t('orders.wizard.media.company_logo') }}</h1>
          <p class="text-muted">{{ $t('orders.wizard.media.subtitle') }}</p>
        </div>
        <div class="card-body">
          <div class="row align-items-center">
            <DropFiles
              :key="orderImages.companyLogo.length"
              class="logo-dropzone col-4 mb-3"
              v-model="orderImages.companyLogo"
              ref="logoDropzone"
            />
            <div class="col-8">
              <h4>{{ order.company_name || 'Undefined company' }}</h4>
              <button class="btn mt-3" @click="openLogoDropzone">
                {{ orderImages.companyLogo?.length ? 'Change' : 'Upload' }} logo...
              </button>
            </div>
          </div>
          <LiFormInput
            class="mt-2 text-muted"
            type="switch"
            :text="`Bild in Zukunft fur ${order.company_name || 'Undefined company'} verwenden`"
            v-model="tempDefaultCompanyLogo"
          />
        </div>
        <div class="card-footer d-flex" v-if="logoUploadedBy">
          <span class="avatar">
            {{ logoUploadedBy.firstname?.charAt(0) || '' }}{{ logoUploadedBy.lastname?.charAt(0) || '' }}
          </span>
          <div class="ms-3">
            <p class="m-0">
              <span class="text-muted">Uploaded by</span> {{ logoUploadedBy.firstname || '' }}
              {{ logoUploadedBy.lastname || '' }}
            </p>
            <p class="text-muted m-0">
              {{ formatDistanceToNow(new Date(logoUploadedBy.created_at || ''), { addSuffix: true }) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl">
      <div class="card card-md h-full">
        <div class="card-header">
          <h1 class="">{{ $t('orders.wizard.media.title') }}</h1>
          <p class="text-muted">{{ $t('orders.wizard.media.subtitle') }}</p>
        </div>
        <div class="card-body">
          <DropFiles class="row flex-wrap g-2" v-model="orderImages.images" :media-count="5" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, onMounted, ref, watch, type Ref } from 'vue';
import DropFiles from '@/components/common/DropZone/index.vue';
import type { Order, OrderMedia } from '@/types/types';
import { FormInput as LiFormInput } from '@prospective/lithium';
import { fetchCompany, fetchCompanyImages } from '@/api/company';
import { formatDistanceToNow } from 'date-fns';

const order = inject('order') as Ref<Order>;
const orderImages = inject('orderImages') as Ref<OrderMedia>;
const setDefaultCompanyLogo = inject('setDefaultCompanyLogo') as Ref<boolean>;
const tempDefaultCompanyLogo = ref(setDefaultCompanyLogo.value);
const startWatchingSwitch = ref(false);
const logoUploadedBy = ref<{ firstname: string | null; lastname: string | null; created_at: string } | undefined>();

onMounted(async () => {
  // If company was chosen in a previous step and there is no current logo
  if (order.value.company_id && !orderImages.value.companyLogo.length) {
    try {
      const { data } = await fetchCompany(order.value.company_id, { resolve: 'logo_uploaded_by,logo_image' });
      if (data?.logo_image_id && data?.logo_image?.data) {
        orderImages.value.companyLogo = [data.logo_image.data];
        tempDefaultCompanyLogo.value = true;
        if (data.logo_uploaded_by?.data) {
          const { firstname, lastname } = data.logo_uploaded_by.data;
          logoUploadedBy.value = { firstname, lastname, created_at: data.logo_image.data.created_at };
        } else logoUploadedBy.value = undefined;
      }
      const res = await fetchCompanyImages(order.value.company_id, { type: 'image' });
      if (Array.isArray(res.data) && res.data.length) {
        orderImages.value.images = res.data;
      }
    } catch (error) {
      console.error('Failed to fetch the company data');
    }
  } else startWatchingSwitch.value = true;
});

watch(
  () => orderImages.value.companyLogo,
  () => {
    if (startWatchingSwitch.value) {
      tempDefaultCompanyLogo.value = false;
      logoUploadedBy.value = undefined;
    }
  },
  { deep: true }
);

// This watch tracks the local switch state and sets the global setDefaultCompanyLogo flag
watch(tempDefaultCompanyLogo, (newVal) => {
  if (!newVal) return (setDefaultCompanyLogo.value = false);
  if (!startWatchingSwitch.value) return (startWatchingSwitch.value = true);
  setDefaultCompanyLogo.value = true;
});

const logoDropzone = ref();
const openLogoDropzone = () => {
  logoDropzone.value.openInput();
};
</script>
<style scoped>
.col-xl {
  max-width: 550px;
}
.card-header {
  flex-direction: column;
  align-items: start;
  padding: 1.5rem 2.5rem 1rem;
}
.card-footer {
  background-color: white;
  padding: 1.5rem 2.5rem;
}
.logo-dropzone {
  padding-left: 0;
}
.logo-dropzone :deep(.dropzone) {
  background-color: var(--tblr-gray-100);
  border-color: var(--tblr-gray-100);
}
</style>
