<template>
  <LiAutocomplete
    v-model="internalValue"
    :disabled="true"
    :search="autocompleteCompany"
    :get-result-value="getResultValue"
    placeholder="Massive Dynamics Inc."
    @submit="handleSelectionChange"
  ></LiAutocomplete>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { Autocomplete as LiAutocomplete } from '@prospective/lithium';

import { fetchCompanies } from '@/api/company';
import type { Company } from '@/types/types';

const emit = defineEmits(['update:companyName', 'update:companyId']);
const props = defineProps<{
  companyId?: number;
  companyName?: string;
}>();

const internalValue = computed({
  get() {
    return props.companyName ?? '';
  },
  set(value) {
    emit('update:companyName', value);
  },
});

function autocompleteCompany(query: string): Promise<Company[]> {
  return new Promise((resolve) => {
    fetchCompanies({ search: query }).then((response) => {
      resolve(response.data);
    });
  });
}

function handleSelectionChange(value: any) {
  emit('update:companyName', value.name);
  emit('update:companyId', value.id);
}

function getResultValue(value: any) {
  return value.name;
}

watch(
  () => props.companyName,
  async () => {
    // When company name exists and company id doesn't
    if (props.companyName && !props.companyId) {
      await autocompleteCompany(props.companyName).then((res: Company[]) => {
        if (Array.isArray(res) && res.length) {
          const found = res.find((c) => c.name === props.companyName);
          if (found) emit('update:companyId', found.id);
        }
      });
    }
  },
  { immediate: true }
);
</script>
