<template>
  <div :class="allowSelection ? 'col-xl-4 col-md-3 col-sm-2' : 'col-3'" v-if="!isError">
    <label class="form-imagecheck" v-if="isUploaded && props.selectable">
      <input
        name=""
        type="checkbox"
        value=""
        class="form-imagecheck-input"
        :checked="props.uploadable.file.selected"
        @change="toggleSelect"
      />
      <span :class="allowSelection ? 'form-imagecheck-figure' : ''">
        <img
          :src="props.uploadable.file?.proxy.url_preview"
          alt="Order image"
          :class="allowSelection ? 'form-imagecheck-image' : 'form-imagecheck-image-list-view'"
        />
        <IconX :size="18" class="form-imagecheck-remove text-danger" role="button" @click.stop="emit('remove')" />
      </span>
    </label>
    <div
      v-else-if="isUploaded"
      class="position-relative img-responsive rounded-3 border logo-image-centered"
      :style="{
        backgroundImage: `url(${props.uploadable.url})`,
        backgroundSize: '80%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <IconX :size="18" class="image-remove text-danger" role="button" @click.stop="emit('remove')" />
    </div>
    <div class="position-relative" v-else-if="!isError">
      <div
        class="img-responsive rounded-3 border"
        :style="{ backgroundImage: `url(${props.uploadable.url})`, opacity: 0.6 }"
      />
      <div class="position-absolute top-50 start-50 translate-middle">
        <div class="spinner-border" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { UploadableFile } from './utils';
import { IconX } from '@tabler/icons-vue';

const props = defineProps<{ uploadable: UploadableFile; selectable?: boolean; allowSelection?: boolean }>();

const isUploaded = computed(() => 100 === props.uploadable.percentage);
const isError = computed(() => false === props.uploadable.status);
const emit = defineEmits(['toggleSelect', 'remove']);

const toggleSelect = (e: any) => {
  emit('toggleSelect', e.target.checked);
};
</script>
<style scoped>
.form-imagecheck {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  margin: auto;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%), linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(45deg, #e0e0e0 25%, #fff 25%);
  background-size: 14px 14px;
  background-position: 0 0, 0 0, -7px -7px, 7px 7px;
}
.form-imagecheck-remove,
.image-remove {
  position: absolute;
  display: none;
  top: 5px;
  right: 5px;
}
.img-responsive {
  background-size: contain;
}
.form-imagecheck-image {
  max-height: 100px;
}
.form-imagecheck-image-list-view {
  max-height: 100px;
  opacity: 1;
}
.form-imagecheck:hover .form-imagecheck-remove,
.img-responsive:hover .image-remove {
  display: block;
}
.logo-image-centered {
  margin: auto;
  display: block;
}
</style>
