import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

import { fetchCustomerFeatures } from '@/api/features';

export type FeatureParamters = { [key: string]: any };
export type FeatureMap = { [key: string]: FeatureParamters };

export interface FeaturePoolMirrorCampaign {
  active_campaign_id?: number;
  override_reference_number?: string;
  publication_minimum_duration?: number;
  logo_url?: string;
}

export interface FeatureOrderManagment {
  provider_selection_mode: 'none' | 'product' | 'provider';
  cost_passthrough?: boolean;
  cpc_t?: number;
  min_total_budget?: number;
  max_total_budget?: number;
  min_daily_budget?: number;
  max_daily_budget?: number;
  min_duration_in_days?: number;
  max_duration_in_days?: number;
  display_cost?: boolean;
  display_budget?: boolean;
  allow_previews?: boolean;
  campaign_management_read_only?: boolean;
}

export interface FeaturePromptsuite {
  conversation_limit?: number;
}

export const useCustomerFeaturesStore = defineStore('customerFeatures', () => {
  const features: Ref<FeatureMap> = ref({});

  async function fetchFeatures() {
    try {
      const response = await fetchCustomerFeatures();
      const tmp = {};

      for (const feature of response.data) {
        tmp[feature.type] = feature.parameters;
      }

      features.value = tmp;
    } catch (error) {
      console.error('Error fetching features:', error);
    }
  }

  function hasFeature(feature: string): boolean {
    return typeof features.value[feature] !== 'undefined';
  }

  const featurePoolMirrorCampaign = computed(() => {
    return features.value.poolmirror_campaigns as FeaturePoolMirrorCampaign | undefined;
  });

  const featureOrderManagment = computed(() => {
    return features.value.order_management as FeatureOrderManagment | undefined;
  });

  const featureCanCreateEditOrder = computed(() => {
    const omFeature = features.value.order_management as FeatureOrderManagment | undefined;
    return omFeature && !omFeature.campaign_management_read_only;
  });

  const featurePromptsuite = computed(() => {
    return features.value.promptsuite as FeaturePromptsuite | undefined;
  });

  return {
    features,
    fetchFeatures,
    hasFeature,
    featurePoolMirrorCampaign,
    featureOrderManagment,
    featureCanCreateEditOrder,
    featurePromptsuite,
  };
});
