export function createFilterEQL(params: { [key: string]: any }) {
  const searchElements: string[] = [];

  for (const key in params) {
    let val = params[key];
    if ([undefined, null, ''].includes(val)) continue;
    const valType = typeof val;

    if (valType === 'string') val = `"${val}"`;
    else if (valType === 'boolean') val = val.toString();
    else if (Array.isArray(val) && val.length) val = val.map((el) => `"${el}"`);
    else if (valType === 'number' && key === 'creator_string') val = `"customer.account:${val}"`;
    else if (valType === 'object') continue;

    searchElements.push(`(${key}: ${val})`);
  }

  return searchElements.join(' AND ');
}
