<template>
  <div class="logo-wrapper">
    <img v-if="imageUrl" :alt="`Logo for company ${props.company?.name}`" :src="imageUrl" />
  </div>
</template>
<script setup lang="ts">
import type { Company } from '@/types/types';
import { computed } from 'vue';

const props = defineProps<{ company?: Company }>();

const imageUrl = computed(() => {
  const { logo_image } = props.company || {};
  if (logo_image?.data) return logo_image.data.proxy.url_thumbnail;
  return '';
});
</script>
<style scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  background: #f7f7f7;
  border-radius: 10px;
}
.logo-wrapper img {
  object-fit: contain;
  max-width: 95%;
  max-height: 95%;
}
</style>
