<template>
  <div class="page page-center" style="height: 100vh">
    <div class="container container-lead py-4">
      <div class="row align-items-center g-4">
        <div class="col-lg">
          <div class="container-tight">
            <div class="text-center mb-4">
              <a href="." class="navbar-brand navbar-brand-autodark mb-4">
                <img :src="defaultLogoTextPath" class="d-md-none" style="max-width: 200px" />
              </a>
            </div>
            <div class="card card-md">
              <div class="card-body">
                <h2 class="h2">{{ $t('reset_password.title', { app: appNameShort }) }}</h2>
              </div>
              <div class="card-body">
                <form action="./" method="get" autocomplete="off" novalidate>
                  <div class="mb-3">
                    <li-form-input
                      v-model="email"
                      type="email"
                      placeholder="your@email.com"
                      :label="$t('email_address')"
                      :errors="errors"
                    />
                  </div>
                </form>
                <div class="form-footer">
                  <li-loading-button class="btn btn-primary w-100" @press="resetPassword" :disabled="!isValidInput">
                    {{ $t('reset_password.reset_button') }}
                    <IconLogin :size="17" :stroke-width="2" class="ms-2" />
                  </li-loading-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg d-none d-lg-block">
          <div class="position-relative">
            <AnimatedPromptLogo></AnimatedPromptLogo>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end px-5">
      <img src="./../assets/logo/pms_logo_light.svg" height="30" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { FormInput as LiFormInput, LoadingButton as LiLoadingButton } from '@prospective/lithium';

import { IconLogin } from '@tabler/icons-vue';
import { appNameShort, defaultLogoTextPath } from '@/config/app';
import { requestUserResetPassword } from '@/api/auth';
import type { ClientError } from '@/api/client';
import { useUserStore } from '@/stores/user';
import AnimatedPromptLogo from '@/components/brand/AnimatedPromptLogo.vue';
import { useToast } from 'vue-toastification';
import translator from '@/locale/translator';

const router = useRouter();
const userStore = useUserStore();
const toast = useToast();

const email = ref('');
const errors = ref<string[]>([]);

const isValidInput = computed(() => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email.value);
});

function resetPassword(stopLoading: () => void) {
  requestUserResetPassword(email.value)
    .then(() => {
      userStore.fetchCurrent().then(async () => {
        router.push({ name: userStore.isLoggedIn ? 'home' : 'login' });
        toast.success(translator.get('reset_password.email_sent'));
      });
    })
    .catch((error: ClientError) => {
      errors.value = error.responseJson.errors ?? [];
    })
    .finally(() => {
      stopLoading();
    });
}
</script>

<style scoped>
.page {
  background-color: #1a191e;
  background-color: #1b1c1e;
}
</style>
