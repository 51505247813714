import WSClient, { type WSClientType } from '@/api/ws';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Ref } from 'vue';

import { fetchSignedInUser } from '@/api/auth';
import type { Customer, User } from '@/types/types';

export const useUserStore = defineStore('users', () => {
  const currentUser: Ref<User | undefined> = ref();
  const currentCustomer: Ref<Customer | undefined> = ref();
  const isLoggedIn: Ref<boolean> = ref(false);
  const hasValidSession: Ref<boolean> = ref(false);
  const ws: Ref<WSClientType | undefined> = ref();

  async function fetchCurrent() {
    try {
      const response = await fetchSignedInUser();
      currentUser.value = response.data as User;
      currentCustomer.value = response.data?.customer?.data as Customer | undefined;
      hasValidSession.value = response.authenticated;
      isLoggedIn.value = response.signed_in;

      // WebSocket connect setup
      // NOTE: when user is not signed_in, ws object is NULL
      if (response.signed_in && !response.ws) console.error('WebSocket not connected');
      else if (response.ws) {
        ws.value = new WSClient(response.ws);
        ws.value.connect();
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
      throw error;
    }
  }

  return {
    currentUser,
    currentCustomer,
    isLoggedIn,
    hasValidSession,
    fetchCurrent,
    ws,
  };
});

export function getUserFullName(user: User): string {
  return `${user.firstname} ${user.lastname}`;
}
