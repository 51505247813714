<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="card card-md product-list-card">
        <div class="card-body">
          <h1 class="">{{ $t('orders.wizard.products.title') }}</h1>
          <p class="text-muted">{{ $t('orders.wizard.products.subtitle') }}</p>
        </div>
        <div class="hr-text hr-text-center hr-text-spaceless">{{ $t('orders.wizard.products.products') }}</div>
        <div class="card-body">
          <div class="form-selectgroup form-selectgroup-boxes d-flex flex-column">
            <TransitionGroup name="list">
              <label v-for="product in selectableProducts" :key="product.id" class="form-selectgroup-item flex-fill">
                <!-- <div class="ribbon ribbon-bookmark bg-green ribbon-end">
                <span class="ms-2">{{ formatCurrency(product.budget_min) }}</span>
              </div> -->
                <input
                  v-model="internalValue"
                  type="checkbox"
                  class="form-selectgroup-input"
                  :true-value="[]"
                  :value="product.id"
                />
                <div class="form-selectgroup-label d-flex align-items-center p-3">
                  <div class="me-3">
                    <span class="form-selectgroup-check"></span>
                  </div>
                  <div class="w-100 form-selectgroup-label-content d-flex align-items-center justify-content-between">
                    <div>
                      <div class="font-weight-bold">
                        <strong>{{ product.name }}</strong>
                      </div>
                      <div class="text-muted">{{ product.description }}</div>
                    </div>
                    <span
                      class="avatar ms-auto"
                      :style="{
                        backgroundImage: `url(${product.image?.data?.proxy.url_thumbnail})`,
                        backgroundSize: 'contain',
                        backgroundColor: 'transparent',
                        width: '80px',
                      }"
                    ></span>
                  </div>
                </div>
              </label>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <table class="table table-transparent table-responsive">
        <thead>
          <tr>
            <th class="text-center" style="width: 1%"></th>
            <th>{{ $t('orders.wizard.products.product') }}</th>
            <th class="text-end" style="width: 1%">{{ $t('orders.wizard.products.min_budget') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in selectedProducts" :key="product.id">
            <td class="text-center">{{ index + 1 }}</td>
            <td>
              <p class="strong mb-1">{{ product.name }}</p>
              <div class="text-muted">{{ product.description }}</div>
            </td>
            <td class="text-end">{{ formatCurrency(product.budget_min) }}</td>
          </tr>
          <tr v-if="selectedProducts.length === 0">
            <td class="text-center"><IconArrowLeft :size="16"></IconArrowLeft></td>
            <td colspan="2" class="text-muted">{{ $t('orders.wizard.products.notice') }}</td>
          </tr>
          <tr v-for="(totalMod, index) in orderCreation.minimum_budget_total_mods" :key="index">
            <td colspan="2" class="text-end text-muted">{{ totalMod.message }}</td>
            <td class="text-end">
              {{ formatCurrency(totalMod.mod) }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-uppercase text-end text-muted">
              {{ $t('orders.wizard.products.total_min_budget') }}
            </td>
            <td class="text-end">
              <strong>{{ formatCurrency(orderCreation.minimum_budget_total) }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-uppercase text-end text-muted">
              {{ $t('orders.wizard.products.min_duration') }}
            </td>
            <td class="text-end">
              <strong>{{ orderCreation.minimum_duration_days }} Tage</strong>
            </td>
          </tr>
          <tr>
            <td>
              <Transition name="fade">
                <div class="spinner-border" v-if="orderLimitsAreUpdating" style="width: 10px; height: 10px"></div>
              </Transition>
            </td>
            <td colspan="1" class="text-uppercase text-end text-muted">
              {{ $t('orders.wizard.products.daily_min_budget') }}
            </td>
            <td class="text-end">
              <strong>{{ formatCurrency(orderCreation.minimum_budget_daily) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue';
import type { Ref } from 'vue';
import type { OrderProduct } from '@/types/types';

import { IconArrowLeft } from '@tabler/icons-vue';

import { formatCurrency } from '@/locale/format';
import type { OrderCreationData } from '../orderCreation';
import { calculateOrderLimits } from '@/api/order';
import type { OrderLimits } from '@/types/types';
import { defaultMinimumCampaignDuration, defaultMinimumDailyBudget } from '@/config/app';
import { addDays, startOfTomorrow } from 'date-fns';

import { PJAS_DATE_FORMAT, formatDateTime } from '@/filters/dateFormat';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

let pjasDateFormat = PJAS_DATE_FORMAT;

const customerFeaturesStore = useCustomerFeaturesStore();
const orderCreation = inject('orderCreation') as Ref<OrderCreationData>;
const products = inject('orderProducts') as Ref<OrderProduct[]>;
const selectedProductsMinTotalBudget = ref(0);
const selectedProductsMinDuration = ref(0);
const selectedProductsMinDailyBudget = ref(0);
const props = defineProps<{
  modelValue?: number[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: number[]): void;
  (event: 'update:orderLimits', value: OrderLimits): void;
}>();

const featureOrderManagment = computed(() => customerFeaturesStore.featureOrderManagment);

const internalValue = computed({
  get() {
    return props.modelValue ?? [];
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const orderLimitsAreUpdating = ref(false);

/**
 * A computed array of the selected product objects
 */
const selectedProducts = computed(() => {
  return (
    products.value?.filter((item) => {
      return internalValue.value.includes(item.id);
    }) || []
  );
});

const selectedProductsStorage = ref<OrderProduct[]>([]);

function storeSelectedProducts() {
  selectedProductsStorage.value = selectedProducts.value;
}

watch(selectedProducts, (newVal) => {
  storeSelectedProducts();
});

// update the minimum budget total on product selection
watch(selectedProducts, (value) => {
  if (selectedProductsStorage.value && selectedProductsStorage.value.length === 1) {
    const selectedProduct = selectedProductsStorage.value[0];
    selectedProductsMinDuration.value = selectedProduct.duration_min;
    selectedProductsMinTotalBudget.value = selectedProduct.budget_min;
    selectedProductsMinDailyBudget.value = selectedProduct.budget_daily_min;
  } else if (selectedProductsStorage.value && selectedProductsStorage.value.length > 0 && featureOrderManagment.value) {
    selectedProductsMinDuration.value = featureOrderManagment.value.min_duration_in_days ?? 0;
    selectedProductsMinTotalBudget.value = featureOrderManagment.value.min_total_budget ?? 0;
    selectedProductsMinDailyBudget.value = featureOrderManagment.value.min_daily_budget ?? 0;
  }
  // get an array of all product ids
  const productIds = value.map((item) => item.id);

  orderLimitsAreUpdating.value = true;

  calculateOrderLimits(productIds)
    .then((res) => {
      orderCreation.value.minimum_budget_total_mods = [];

      for (const mod of res.total_budget_modifications) {
        orderCreation.value.minimum_budget_total_mods.push({
          mod: mod[0],
          message: mod[1],
        });
      }
      if (selectedProducts.value.length === 0 && featureOrderManagment.value) {
        orderCreation.value.minimum_budget_total = featureOrderManagment.value.min_total_budget ?? 0;
        orderCreation.value.minimum_budget_daily = featureOrderManagment.value.min_daily_budget ?? 0;
        orderCreation.value.minimum_duration_days = featureOrderManagment.value.min_duration_in_days ?? 0;
      } else if (selectedProducts.value.length === 1) {
        orderCreation.value.minimum_budget_total = selectedProductsMinTotalBudget.value;
        orderCreation.value.minimum_budget_daily = selectedProductsMinDailyBudget.value;
        orderCreation.value.minimum_duration_days = selectedProductsMinDuration.value;
      } else if (selectedProducts.value.length > 1) {
        orderCreation.value.minimum_budget_total = res.min_total_budget;
        orderCreation.value.minimum_budget_daily = res.min_daily_budget;
        orderCreation.value.minimum_duration_days = res.min_duration_days;
      }

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + orderCreation.value.minimum_duration_days);

      const tomorrowStringified = formatDateTime(startOfTomorrow());
      const endDateStringified = formatDateTime(addDays(startOfTomorrow(), orderCreation.value.minimum_duration_days));
      if (selectedProducts.value.length === 0 && featureOrderManagment.value) {
        emit('update:orderLimits', {
          minTotalBudget: featureOrderManagment.value.min_total_budget ?? 0,
          minDailyBudget: featureOrderManagment.value.min_daily_budget ?? 0,
          minDurationDays: featureOrderManagment.value.min_duration_in_days ?? 0,
          calculatedStartDate: tomorrowStringified,
          calculatedEndDate: endDateStringified,
        });
      } else if (selectedProducts.value.length === 1) {
        emit('update:orderLimits', {
          minTotalBudget: selectedProductsMinTotalBudget.value,
          minDailyBudget: selectedProductsMinDailyBudget.value,
          minDurationDays: selectedProductsMinDuration.value,
          calculatedStartDate: tomorrowStringified,
          calculatedEndDate: endDateStringified,
        });
      } else if (selectedProducts.value.length > 1) {
        emit('update:orderLimits', {
          minTotalBudget: res.min_total_budget,
          minDailyBudget: res.min_daily_budget,
          minDurationDays: res.min_duration_days,
          calculatedStartDate: tomorrowStringified,
          calculatedEndDate: endDateStringified,
        });
      } else {
        emit('update:orderLimits', {
          minTotalBudget: defaultMinimumDailyBudget * defaultMinimumCampaignDuration,
          minDailyBudget: defaultMinimumDailyBudget,
          minDurationDays: defaultMinimumCampaignDuration,
          calculatedStartDate: tomorrowStringified,
          calculatedEndDate: formatDateTime(endDate, pjasDateFormat),
        });
      }
    })
    .finally(() => {
      orderLimitsAreUpdating.value = false;
    });
});

/**
 * An array of product ids that should be excluded from the selection
 */
const selectionExcludedIds = computed<number[]>(() => {
  let excludedIds: number[] = [];
  for (const product of selectedProducts.value || []) {
    for (const id of product.excludes) {
      if (!excludedIds.includes(id)) {
        excludedIds.push(id);
      }
    }

    // if one product excludes all others
    // we iterate all products except the current one and
    // add them to our excludes
    if (product.excludes_all) {
      excludedIds = [];
      for (const otherProduct of products.value) {
        if (otherProduct.id !== product.id) {
          excludedIds.push(otherProduct.id);
        }
      }
      return excludedIds;
    }
  }
  return excludedIds;
});

/**
 * An array of all products currently selectable.
 */
const selectableProducts = computed(() => {
  return (
    products.value?.filter((item) => {
      return !selectionExcludedIds.value.includes(item.id);
    }) || []
  );
});
</script>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-leave-active {
  position: absolute;
}
</style>
