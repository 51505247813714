<template>
  <div>
    <At :members="mentionsArray" name-key="key">
      <template v-slot:item="s">
        <span v-text="s.item.name"></span>
        <span v-if="s.item.type === 'user'" class="blue-dot ms-2"></span>
      </template>
      <div
        :contenteditable="!props.disabled"
        ref="contenteditable"
        class="contenteditable col-12 w-full"
        :class="{ disabled: props.disabled }"
        autocomplete="off"
        v-bind="attrs"
        :value="props.modelValue"
        @input="onInput"
        @keydown.enter="onEnter"
      ></div>
    </At>
    <slot />
  </div>
</template>

<script setup lang="ts">
import At from 'vue-at/dist/vue-at'; // for contenteditable
import { ref, useAttrs, computed, watch } from 'vue';

/* --------------------------------- PROPS & EMITS -------------------------------- */
const props = defineProps<{
  modelValue?: string;
  mentions?: { [mention: string]: unknown };
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'submit'): void;
}>();

/* --------------------------------- STATE -------------------------------- */

const attrs = useAttrs();
const contenteditable = ref<null | HTMLDivElement>(null);

watch(
  () => props.modelValue,
  () => {
    adjustHeight();

    if (!props.modelValue && contenteditable.value) {
      contenteditable.value.innerText = '';
    }
  }
);

/* --------------------------------- INTERFACE -------------------------------- */

interface Mention {
  key: string;
  actor: string;
  type: string;
  name: string;
}

/* -------------------------------- COMPUTED -------------------------------- */
const mentionsArray = computed(() => {
  const mentions = (props.mentions as Record<string, Mention>) || {};
  return Object.entries(mentions).map(([key, mention]) => ({
    key,
    name: mention.name,
    actor: mention.actor,
    type: mention.type,
  }));
});

/* -------------------------------- FUNCTIONS ------------------------------- */

const onInput = (event: Event) => {
  adjustHeight();
  const content = contenteditable.value?.innerText || '';
  emit('update:modelValue', content);
};

const onEnter = (event: KeyboardEvent) => {
  if (event.key !== 'Enter' || event.shiftKey) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  emit('submit');
};

const adjustHeight = () => {
  if (contenteditable.value) {
    contenteditable.value.style.height = 'auto';
    contenteditable.value.style.height = `${contenteditable.value.scrollHeight}px`;
  }
};
</script>
<style>
.blue-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(0, 102, 255);
}
.atwho-wrap div {
  min-height: 40px;
}
.atwho-wrap div:focus-visible {
  outline: none;
  box-shadow: none;
  border-color: #e5e6e8;
}
.atwho-wrap div:empty::after {
  content: 'Type your message here...';
  color: #a7a7a7;
}
.atwho-view {
  min-width: 350px !important;
  max-width: 550px !important;
  bottom: 40px !important;
}
.disabled {
  background-color: rgb(199, 199, 199); /* Change to the desired background color when disabled */
  cursor: not-allowed;
  color: #ccc; /* Change to the desired text color when disabled */
}
</style>
