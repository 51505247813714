<template>
  <div class="d-flex p-2 border rounded">
    <div class="col-9">
      <h4>{{ props.product.name }}</h4>
      <p class="text-muted m-0">{{ props.product.description }}</p>
    </div>
    <div class="col-3">
      <span
        class="avatar ms-auto"
        :style="{
          backgroundImage: `url(${props.product.image?.data?.proxy.url_thumbnail})`,
          backgroundSize: 'contain',
          backgroundColor: 'transparent',
          width: '80px',
        }"
      ></span>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { OrderProduct } from '@/types/types';

const props = defineProps<{
  product: OrderProduct;
}>();
</script>
