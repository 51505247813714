import type { ResponseData } from '@/api/client';
import type { FeaturePoolMirrorCampaign } from '@/stores/customerFeatures';

export function canDeactivateJob(
  job: ResponseData,
  publication?: ResponseData,
  featureSettings?: FeaturePoolMirrorCampaign | undefined
): boolean {
  // no publication yet so we can deactivate without much trouble
  if (!publication) {
    return true;
  }

  const publicationMinimumDuration = featureSettings?.publication_minimum_duration ?? 60 * 60 * 24 * 10; // 10 days minimum is default
  const publicationPublishDate = new Date(publication.published_at);

  // if the publication is older than the minimum duration, we can deactivate
  return new Date().getTime() - publicationPublishDate.getTime() > publicationMinimumDuration * 1000;
}
