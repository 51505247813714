<template>
  <div class="row flex-nowrap">
    <OrderCompanyLogo :company="props.order.company?.data" class="me-3" />
    <div class="flex-fill me-1">
      <h4 class="fw-bold">{{ props.order.company_name }}</h4>
      <router-link :to="`/dashboard/orders/detail/${props.order.id}`" class="d-grid text-reset">
        <h2 class="fw-bold text-truncate">{{ props.order.title }}</h2></router-link
      >
      <h4 class="text-muted fw-normal">{{ props.order.provider_job_id }}</h4>
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import OrderCompanyLogo from './OrderCompanyLogo.vue';
import type { Order } from '@/types/types';

const props = defineProps<{ order: Order }>();
</script>
