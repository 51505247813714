<template>
  <div v-if="featureStore.featurePoolMirrorCampaign">
    <HeaderNavbar>
      <HeaderNavbarSearch :model-value="searchQuery"></HeaderNavbarSearch>
    </HeaderNavbar>
    <div class="container-lead">
      <li-page-header
        class="h2"
        :title="$t('cvanalyzer.list.title')"
        :subtitle="$t('nav.active_poolmirror_campaign') + ' ' + '...'"
        :has-filters="false"
      >
      </li-page-header>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PageHeader as LiPageHeader } from '@prospective/lithium';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';

const featureStore = useCustomerFeaturesStore();

const searchQuery = ref('');
</script>

<style></style>
