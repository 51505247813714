<template>
  <div
    class="card poolmirror-campaign-card poolmirror-campaign-card-slot position-relative"
    :class="{ 'card-inactive': !campaignSlot.crawled_job }"
    :style="{ opacity: campaignSlot.crawled_job ? 1.0 : 0.5 }"
  >
    <div
      class="deactivation-button position-absolute"
      :class="{ 'deactivation-button-in-progress': deactivationInProgress }"
      v-if="canBeDeactivated"
    >
      <LoadingButton class="btn btn-orange" @press="deactivateJob"
        >JobIQ Deaktivieren <IconCloudOff :size="16" class="ms-2"></IconCloudOff
      ></LoadingButton>
    </div>

    <div class="p-3">
      <div class="row">
        <div class="col-1" style="width: 54px">
          <span class="avatar">
            <span>#{{ campaignSlot.id }}</span>
          </span>
        </div>
        <div class="col">
          <div v-if="campaignSlot.crawled_job">
            <div class="card-company-name nobr">
              {{ campaignSlot.crawled_job.company_name }}
            </div>
            <h3 class="card-title nobr">
              <a :href="campaignSlot.crawled_job.link" target="_blank">{{ campaignSlot.crawled_job.title }}</a>
            </h3>
            <div class="d-flex">
              <span class="text-muted"
                >#{{ campaignSlot.crawled_job.reference_num }} • {{ campaignSlot.crawled_job.provider_key }}</span
              >
            </div>
          </div>
          <div v-else>
            <div class="card-company-name nobr">...</div>
            <h3 class="card-title nobr">Free Slot {{ campaignSlot.id }}</h3>
            <span class="text-muted">#</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center p-3 pt-0" v-if="campaignSlot.crawled_job">
      <div class="me-2">
        <span class="badge bg-blue-lt nobr" :style="{ maxWidth: '150px' }">{{
          campaignSlot.crawled_job.workplace_location
        }}</span>
      </div>
      <div class="me-2">
        <span class="badge bg-blue-lt">{{
          campaignSlot.crawled_job.properties.ohws_attr_stellenprozent ?? 'Unknown'
        }}</span>
      </div>
      <div class="ms-auto" v-if="activeStatusText">
        <span class="status slot-status" :class="'status-green'">
          <span class="status-dot status-dot-animated"></span>
          {{ activeStatusText }}
        </span>
      </div>
    </div>
    <div class="d-flex p-3 pt-0" v-else>
      <div class="me-2">
        <span class="badge bg-blue-lt">...</span>
      </div>
      <div class="me-2">
        <span class="badge bg-blue-lt">...</span>
      </div>
      <div class="me-2">
        <span class="badge bg-blue-lt">...</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LoadingButton } from '@prospective/lithium';
import { ref, computed } from 'vue';

import { IconCloudOff } from '@tabler/icons-vue';

import type { Slot } from './PoolmirrorDetailPage.vue';
import { setPoolmirrorCrawledJobPriority } from '@/api/poolmirror';
import type { ResponseData } from '@/api/client';
import { formatDistanceToNow } from 'date-fns';
import { canDeactivateJob } from '@/func/poolmirror/state';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';

const featureStore = useCustomerFeaturesStore();

const deactivationInProgress = ref(false);

const props = defineProps<{
  campaignSlot: Slot;
  publication?: ResponseData;
}>();

const emits = defineEmits<{
  (event: 'did-change-priority'): void;
}>();

const activeStatusText = computed(() => {
  if (!props.publication) {
    return;
  }

  if (props.publication.published_at === null) {
    return 'Waiting...';
  }

  // return a date ago since pulishing with date-fns
  return formatDistanceToNow(new Date(props.publication.published_at), { addSuffix: true });
});

const canBeDeactivated = computed(() => {
  if (!props.campaignSlot.crawled_job) {
    return false;
  }

  return canDeactivateJob(props.campaignSlot.crawled_job, props.publication, featureStore.featurePoolMirrorCampaign);
});

function deactivateJob(stopLoading: () => void) {
  deactivationInProgress.value = true;

  let crawledJobId = props.campaignSlot.crawled_job?.id ?? 0;

  setPoolmirrorCrawledJobPriority(props.campaignSlot.campaign_id, crawledJobId, 0).then(() => {
    emits('did-change-priority');
    deactivationInProgress.value = false;
    stopLoading();
  });
}
</script>

<style scoped>
.slot-status {
  font-size: 10px;
}
.poolmirror-campaign-card-slot {
  border: 4px solid #3f83db !important;
}

.poolmirror-campaign-card-slot > div {
  height: 100%;
}
.poolmirror-campaign-card-slot .deactivation-button {
  opacity: 0;
  transform: translateY(-20px);
  transition: all ease-in-out 0.4s;
}
.lead-available-slots-container.expanded .poolmirror-campaign-card-slot:hover .deactivation-button,
.lead-available-slots-container.expanded
  .poolmirror-campaign-card-slot
  .deactivation-button.deactivation-button-in-progress {
  opacity: 1;
  transform: translateY(0px);
}

.deactivation-button {
  top: 15px;
  right: 15px;
}
</style>
