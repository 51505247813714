// useRequestQueue.js

import { ref, onMounted } from 'vue';

// Shared instance of the queue
const requestQueue = ref<any[]>([]);
const isFetching = ref(false);

export function addToQueue(request: any) {
  requestQueue.value.push(request);

  if (!isFetching.value) {
    processQueue();
  }
}

async function processQueue() {
  isFetching.value = true;

  while (requestQueue.value.length > 0) {
    const request = requestQueue.value.shift();

    try {
      await request();
    } catch (error) {
      console.error('Error in async request:', error);
    }
  }

  isFetching.value = false;
}

// Hook to clear the queue when the application is unmounted
onMounted(() => {
  return () => {
    requestQueue.value = [];
  };
});

export function useRequestQueue() {
  return {
    addToQueue,
    isFetching,
  };
}
