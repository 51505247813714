<template>
  <Modal
    v-model="eulaModalVisible"
    :canClose="false"
    :closeOnEsc="false"
    :closeOnBackdropClick="false"
    :title="eula?.eula?.data.name ?? 'EULA Loading...'"
    class="modal-dialog-scrollable"
  >
    <template #body>
      <div v-html="formattedEulaText" v-if="eula?.eula?.data.text"></div>
      <div v-else>
        <div class="text-secondary mb-3">Loading EULA...</div>
        <div class="progress progress-sm">
          <div class="progress-bar progress-bar-indeterminate"></div>
        </div>
      </div>
    </template>

    <template #footer>
      <LoadingButton type="button" class="btn btn-primary" @press="userAcceptsEula">
        {{ $t('eula.accept') }} <IconCircleCheckFilled class="ms-2"></IconCircleCheckFilled>
      </LoadingButton>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { acceptEula, fetchUnacceptedEula } from '@/api/auth';
import { useUserStore } from '@/stores/user';
import type { EulaAssignment } from '@/types/types';
import { LoadingButton, Modal } from '@prospective/lithium';
import { IconCircleCheckFilled } from '@tabler/icons-vue';
import { marked } from 'marked';
import { computed, onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

/* --------------------------------- STATE -------------------------------- */

const userStore = useUserStore();
const toast = useToast();

const eulaModalVisible = ref(false);
const eula = ref<EulaAssignment | undefined>();

/* --------------------------------- COMPUTED -------------------------------- */

const formattedEulaText = computed(() => {
  return marked.parse(eula.value?.eula?.data.text ?? '');
});

/* --------------------------------- METHODS -------------------------------- */

function requestUserToAcceptEula() {
  eulaModalVisible.value = true;
  fetchUnacceptedEula().then((response) => {
    eula.value = response.data;
  });
}

function userAcceptsEula(stopLoading: () => void) {
  acceptEula(eula.value?.id ?? 0)
    .then(() => {
      userStore.fetchCurrent();
      eulaModalVisible.value = false;
      // hotfix eula modal not closing after accepting (force reload)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(() => {
      toast.error('Could not accept EULA');
    })
    .finally(() => {
      stopLoading();
    });
}

/* --------------------------------- LIFECYCLE -------------------------------- */

onMounted(() => {
  // this component should only be attached to the document
  // when there is an unaccepted EULA, but we double check here
  if (userStore.currentCustomer?.has_unaccapted_eula === true) {
    requestUserToAcceptEula();
  }
});
</script>
